import {
  ec as starkEc,
  sign as starkSign,
} from '@starkware-industries/starkware-crypto-utils'

import type { HexString } from '@x10/lib-core/types'
import { fromHexString } from '@x10/lib-core/utils'

export const signMessage = (messageHash: string, starkPrivateKey: HexString) => {
  const starkPrivateKeyWithoutPrefix = fromHexString(starkPrivateKey)
  const starkKeyPair = starkEc.keyFromPrivate(starkPrivateKeyWithoutPrefix, 'hex')
  const starkPublicKey = starkKeyPair.getPublic().getX().toJSON()

  const signature = starkSign(starkKeyPair, messageHash)

  return {
    signature: {
      r: signature.r.toString('hex'),
      s: signature.s.toString('hex'),
    },
    starkKey: starkPublicKey,
  }
}
