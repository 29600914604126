import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { HStack } from '@x10/lib-styled-system/jsx'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'

type NotificationWithdrawProps = {
  state: 'created' | 'completed' | 'failed'
  amount: Decimal | number
  duration?: string
}

export const NotificationWithdraw: FC<NotificationWithdrawProps> = ({
  state,
  amount,
  duration,
}) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: false })

  if (state === 'created') {
    return (
      <Toast.Root>
        <Notification.Avatar>
          <SvgIcon.SvgIconTime />
        </Notification.Avatar>

        <Notification.Content>
          <Notification.TitleAndSide>
            <Notification.Title>
              <FormattedMessage
                id="core.component.notification.withdraw.created.title"
                defaultMessage="Withdrawal Created"
              />
            </Notification.Title>
            <Notification.Side>
              <Notification.Value>
                {formatMarketAsset(
                  {
                    amount: amount,
                    type: 'collateral',
                  },
                  { precision: AssetPrecision.AsIs },
                )}{' '}
                USDC
              </Notification.Value>
            </Notification.Side>
          </Notification.TitleAndSide>
          <Notification.Description>
            <FormattedMessage
              id="core.component.notification.withdraw.created.arrival-time.title"
              defaultMessage="Arrival time up to {eta}"
              values={{ eta: duration }}
            />
          </Notification.Description>
        </Notification.Content>
      </Toast.Root>
    )
  }

  if (state === 'failed') {
    return (
      <Toast.Root>
        <Notification.Avatar
          css={{
            bg: 'token.orange',
          }}
        >
          <SvgIcon.SvgIconCross />
        </Notification.Avatar>

        <Notification.Content>
          <Notification.TitleAndSide>
            <Notification.Title>
              <FormattedMessage
                id="core.component.notification.withdraw.failed.title"
                defaultMessage="Withdrawal Failed"
              />
            </Notification.Title>
            <Notification.Side>
              <Notification.Value>
                {formatMarketAsset({
                  amount: amount,
                  type: 'collateral',
                })}{' '}
                USDC
              </Notification.Value>
            </Notification.Side>
          </Notification.TitleAndSide>
        </Notification.Content>
        <Toast.CloseTrigger />
      </Toast.Root>
    )
  }

  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.green',
        }}
      >
        <SvgIcon.SvgIconWithdrawal />
      </Notification.Avatar>

      <Notification.Content>
        <HStack
          css={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Notification.Title>
            <FormattedMessage
              id="core.component.notification.withdraw.completed.title"
              defaultMessage="Withdrawal Completed"
            />
          </Notification.Title>
          <Notification.Side
            css={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Notification.Value
              css={{
                textWrap: 'nowrap',
              }}
            >
              {formatMarketAsset({
                amount: amount,
                type: 'collateral',
              })}{' '}
              USDC
            </Notification.Value>
          </Notification.Side>
        </HStack>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
