import { create } from 'zustand'
import { getAccount } from '@wagmi/core'

import { useFeaturesStore } from '@src/domain/core/store/features'
import { appLocalStorage } from '@src/domain/core/utils/storage/app-local-storage'

import { wagmiConfig } from '../providers/wagmi-provider'

type WalletEligibilityState = {
  status:
    | 'UNKNOWN'
    | 'REFERRAL_CODE_REQUIRED'
    | 'UNLOCKING'
    | 'ELIGIBLE'
    | 'ELIGIBLE_CONFIRMED'
  isClosedMainnetBannerVisible: boolean
}

export const useWalletEligibilityStore = create<WalletEligibilityState>(() => {
  const knownWallets = appLocalStorage.getItem<string[]>('known-wallets-addresses') ?? []
  const isClosedMainnet = useFeaturesStore.getState().features.includes('MAINNET_CLOSED')
  const shouldShowBannerByDefault = knownWallets.length === 0 && isClosedMainnet
  const activeWallet = getAccount(wagmiConfig).address
  const isKnownWallet = knownWallets.includes(activeWallet ?? '')

  const initialState: WalletEligibilityState = {
    status: isKnownWallet ? 'ELIGIBLE_CONFIRMED' : 'UNKNOWN',
    isClosedMainnetBannerVisible:
      appLocalStorage.getItem<boolean>('show-closed-mainnet-banner') ??
      shouldShowBannerByDefault,
  }

  return initialState
})

export const walletEligibilityActions = {
  setReferralCodeRequired() {
    useWalletEligibilityStore.setState({
      status: 'REFERRAL_CODE_REQUIRED',
    })
  },

  hideClosedMainnetBanner() {
    appLocalStorage.setItem('show-closed-mainnet-banner', false)
    useWalletEligibilityStore.setState({ isClosedMainnetBannerVisible: false })
  },

  startUnlocking() {
    useWalletEligibilityStore.setState({ status: 'UNLOCKING' })
  },

  finishUnlocking() {
    useWalletEligibilityStore.setState({ status: 'ELIGIBLE' })
    this.hideClosedMainnetBanner()
  },

  finishFlow() {
    useWalletEligibilityStore.setState({ status: 'ELIGIBLE_CONFIRMED' })
  },

  triggerPostAuthUnlocking() {
    const { isClosedMainnetBannerVisible } = useWalletEligibilityStore.getState()

    if (isClosedMainnetBannerVisible) {
      this.startUnlocking()
    }
  },

  reset() {
    useWalletEligibilityStore.setState({ status: 'UNKNOWN' })
  },
}
