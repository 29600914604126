import { create } from 'zustand'

import type { Balance } from '@src/domain/api/x10'

const INITIAL_STATE = {
  status: null,
  availableForTrade: null,
}

/**
 * This is a subset of last balance we got from previous API polling or WebSocket event.
 * Used for liquidation notifications where lots of triggers are depend on
 * status change from one to another.
 */
export const usePreviousBalanceState = create<{
  status: Balance['status'] | null
  availableForTrade: Balance['availableForTrade'] | null
}>(() => INITIAL_STATE)

export const resetPreviousBalanceState = () => {
  usePreviousBalanceState.setState(INITIAL_STATE)
}
