import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@src/domain/api/x10'
import { AuthQueryKey } from '@src/domain/core/constants'
import { QueryKey as TradeQueryKey } from '@src/domain/trade/constants'

export const useCreateSubAccount = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: API.trading.account.createSubAccount,

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [AuthQueryKey.Info] })
      await queryClient.invalidateQueries({ queryKey: [TradeQueryKey.UserAccountsInfo] })
    },
  })
}
