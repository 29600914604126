import { type FC } from 'react'

import type { CryptoCurrencySyntheticCode } from '@x10/lib-core/config'
import { FormattedMessage, type MessageDescriptor } from '@x10/lib-core/i18n'
import { getAssetUrl, notReachable } from '@x10/lib-core/utils'
import { Logo, Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { ApiErrorCode } from '@src/domain/core/errors/base'
import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'

const getTradeMessages = (event: SimpleTradeEvents): MessageDescriptor => {
  switch (event) {
    case 'placed':
      return {
        id: 'core.component.notification.order.placed.title',
        defaultMessage: 'Order Submitted',
      }
    case 'edit':
      return {
        id: 'core.component.notification.order.edit.title',
        defaultMessage: 'Order Modified',
      }
    case 'cancelled':
      return {
        id: 'core.component.notification.order.cancelled.title',
        defaultMessage: 'Order Cancelled',
      }
    case 'rejected':
      return {
        id: 'core.component.notification.order.rejected.title',
        defaultMessage: 'Order Rejected',
      }
    default:
      return notReachable(event)
  }
}

const getReasonMessage = (
  reason: TradeRejectionReasons | undefined,
): MessageDescriptor | undefined => {
  switch (reason) {
    case 'order-price-invalid':
      return {
        id: 'core.component.notification.order.rejected.reason.order-price-invalid',
        defaultMessage: 'Order price is invalid.',
      }
    case 'order-size-invalid':
      return {
        id: 'core.component.notification.order.rejected.reason.order-size-invalid',
        defaultMessage: 'Order size is invalid.',
      }
    case 'maximum-open-orders-exceeded':
      return {
        id: 'core.component.notification.order.rejected.reason.maximum-open-orders-exceeded',
        defaultMessage: 'Maximum number of open orders per market is 200.',
      }
    case 'reduce-only-order-in-same-direction':
      return {
        id: 'core.component.notification.order.rejected.reason.reduce-only-order-in-same-direction',
        defaultMessage:
          'Reduce-only orders in the same direction as the position are not permitted.',
      }
    case 'reduce-only-no-position':
      return {
        id: 'core.component.notification.order.rejected.reason.reduce-only-no-position',
        defaultMessage: 'You cannot place a reduce-only order without an open position.',
      }
    case 'reduce-only-order-size':
      return {
        id: 'core.component.notification.order.rejected.reason.reduce-only-order-size',
        defaultMessage:
          'Reduce-only order size must be less than the open position minus open reducing orders.',
      }
    case 'maximum-position-value-exceeded':
      return {
        id: 'core.component.notification.order.rejected.reason.maximum-position-value-exceeded',
        defaultMessage: 'If executed, this order will exceed the maximum position value.',
      }
    case 'insufficient-balance':
      return {
        id: 'core.component.notification.order.rejected.reason.insufficient-balance',
        defaultMessage: 'Your available balance is insufficient to place the order.',
      }
    case 'contradict-post-only':
      return {
        id: 'core.component.notification.order.rejected.reason.contradict-post-only',
        defaultMessage:
          'If placed, the order would contradict the post-only requirement.',
      }
    case 'execution-price-exceeds-5':
      return {
        id: 'core.component.notification.order.rejected.reason.execution-price-exceeds-5',
        defaultMessage: 'There is no liquidity at this price.',
      }
    case undefined:
      return undefined
    default:
      return notReachable(reason)
  }
}

export type TradeRejectionReasons =
  | 'order-price-invalid'
  | 'order-size-invalid'
  | 'maximum-open-orders-exceeded'
  | 'reduce-only-order-in-same-direction'
  | 'reduce-only-order-size'
  | 'reduce-only-no-position'
  | 'maximum-position-value-exceeded'
  | 'insufficient-balance'
  | 'contradict-post-only'
  | 'execution-price-exceeds-5'

const ERRORS_TO_TRADE_REJECTION_REASONS: Record<string, TradeRejectionReasons> = {
  [ApiErrorCode.OrderQtyLessThanMinTradeSize]: 'order-size-invalid',
  [ApiErrorCode.InvalidQtyWrongSizeIncrement]: 'order-size-invalid',
  [ApiErrorCode.OrderValueExceedsMaxOrderValue]: 'order-size-invalid',
  [ApiErrorCode.InvalidQtyPrecision]: 'order-size-invalid',
  [ApiErrorCode.InvalidPriceWrongPriceMovement]: 'order-price-invalid',
  [ApiErrorCode.InvalidPricePrecision]: 'order-price-invalid',
  [ApiErrorCode.MaxOpenOrdersNumberExceeded]: 'maximum-open-orders-exceeded',
  [ApiErrorCode.MaxPositionValueExceeded]: 'maximum-position-value-exceeded',
  [ApiErrorCode.InvalidPositionTpslQty]: 'order-size-invalid',
  [ApiErrorCode.MissingOrderPrice]: 'order-price-invalid',
  [ApiErrorCode.ReduceOnlyOrderSizeExceedsPositionSize]: 'reduce-only-order-size',
  [ApiErrorCode.ReduceOnlyOrderPositionIsMissing]: 'reduce-only-no-position',
  [ApiErrorCode.ReduceOnlyOrderPositionSameSide]: 'reduce-only-order-in-same-direction',
  [ApiErrorCode.OrderCostExceedsBalance]: 'insufficient-balance',
  [ApiErrorCode.InvalidPriceAmount]: 'order-price-invalid',

  // Subset of rejection/cancellation reasons from X10 matching engine:
  // https://github.com/x10xchange/matching-engine-v2/blob/main/src/main/kotlin/exchange/x10/matching/model/order/OrderStatusReason.kt
  INVALID_PRICE: 'order-price-invalid',
  INVALID_QTY: 'order-size-invalid',
  NO_LIQUIDITY: 'execution-price-exceeds-5',
  NOT_ENOUGH_FUNDS: 'reduce-only-order-in-same-direction',
  POST_ONLY_FAILED: 'contradict-post-only',
  REDUCE_ONLY_FAILED: 'reduce-only-order-size',
}

const getTradeRejectionReason = (
  error: string | number | undefined,
): TradeRejectionReasons | undefined => {
  const reason = error ? ERRORS_TO_TRADE_REJECTION_REASONS[error] : undefined

  if (!reason) {
    // if error is DEFINED but not in the mapping, we want to know about it
    error &&
      captureExceptionWithSentry(new Error(`Unknown trade rejection reason: ${error}`))

    return undefined
  }

  return reason
}

export type SimpleTradeEvents = 'placed' | 'edit' | 'cancelled' | 'rejected'

const eventToIconMapping = (
  event: SimpleTradeEvents,
  code: CryptoCurrencySyntheticCode,
) => {
  switch (event) {
    case 'edit':
    case 'placed':
      return (
        <Logo
          url={getAssetUrl({
            type: 'crypto',
            name: code,
          })}
          boxSize={40}
        />
      )
    case 'cancelled':
      return <SvgIcon.SvgIconExclamationTriangle />
    case 'rejected':
      return <SvgIcon.SvgIconCross />
    default:
      return notReachable(event)
  }
}

const eventToBgColorMapping = (event: SimpleTradeEvents): string => {
  switch (event) {
    case 'edit':
    case 'placed':
      return 'token.green'
    case 'cancelled':
      return 'token.orange'
    case 'rejected':
      return 'token.red'
    default:
      return notReachable(event)
  }
}

export const NotificationTradeSimple: FC<{
  event: SimpleTradeEvents
  marketName: MarketName
  reason?: string | number
}> = ({ event, marketName, reason }) => {
  const reasonMessage = getReasonMessage(getTradeRejectionReason(reason))
  const message = getTradeMessages(event)
  const getCachedMarket = useGetCachedMarket()

  const { assets } = getCachedMarket(marketName)
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: eventToBgColorMapping(event),
        }}
      >
        {eventToIconMapping(event, assets.synthetic.code)}
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage {...message} />
          </Notification.Title>
        </Toast.Title>

        {event === 'rejected' && reasonMessage && (
          <Toast.Description asChild>
            <Notification.Description>
              <FormattedMessage {...reasonMessage} />
            </Notification.Description>
          </Toast.Description>
        )}
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
