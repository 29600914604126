import { useCallback } from 'react'
import { err, ok } from 'neverthrow'
import { useAccount } from 'wagmi'

import { invariant } from '@x10/lib-core/utils'

import { loadAccountPrivateKey } from '@src/domain/core/utils/auth'

import { useAccountInfo, useRegenerateKey } from './use-auth'

export const useAccountStarkPrivateKey = () => {
  const currentAccount = useAccountInfo()
  const regenerateKey = useRegenerateKey()
  const account = useAccount()

  const getStarkPrivateKey = useCallback(
    async (accountIndex?: number) => {
      const index = accountIndex ?? currentAccount?.accountIndex

      invariant(
        account.address,
        'Account is not connected, cannot get stark private key.',
      )
      invariant(
        index !== undefined,
        'Account index is not provided, or account is not connected.',
      )

      const starkPrivateKey = loadAccountPrivateKey(account.address, index)

      if (starkPrivateKey) {
        return ok(starkPrivateKey)
      }

      const keyResult = await regenerateKey(index)
      if (keyResult.isErr()) {
        return err(keyResult.error)
      }
      return keyResult
    },
    [account.address, currentAccount, regenerateKey],
  )

  return { getStarkPrivateKey }
}
