import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount } from 'wagmi'
import { create } from 'zustand'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { getAssetUrl, invariant } from '@x10/lib-core/utils'
import { Box, HStack, VStack } from '@x10/lib-styled-system/jsx'
import {
  Button,
  Checkbox,
  Dialog,
  Portal,
  Step,
  Tooltip,
  type StepStatus,
} from '@x10/lib-ui-kit/components'

import {
  checkKnownWallet,
  useAuthenticate,
  useRegenerateKey,
} from '@src/domain/auth/hooks/use-auth'
import { useClientSettingsStore } from '@src/domain/core/store/user-settings'
import { DialogCloseTrigger } from '@src/domain/core/ui/components/dialog/dialog-close-trigger'
import { DialogContent } from '@src/domain/core/ui/components/dialog/dialog-content'
import { DialogImageIcon } from '@src/domain/core/ui/components/dialog/dialog-image-icon'
import { addDelayBeforeNextStep } from '@src/domain/core/utils/add-delay-before-next-step'
import { loadAccountPrivateKey } from '@src/domain/core/utils/auth'

const useCreateAccountDialogState = create(() => ({
  isOpen: false,
}))

export const openCreateAccountDialog = () => {
  useCreateAccountDialogState.setState({ isOpen: true })
}

const closeCreateAccountDialog = () => {
  useCreateAccountDialogState.setState({ isOpen: false })
}

const CreateAccountDialogContent = () => {
  const regenerateKey = useRegenerateKey()
  const authenticate = useAuthenticate()
  const { address } = useAccount()
  const setRememberMeSetting = useClientSettingsStore((state) => state.setRememberMe)
  const rememberMeSetting = useClientSettingsStore((state) => state.rememberMe)
  const [rememberMe, setRememberMe] = useState(rememberMeSetting ?? true)

  invariant(address, 'Address should be defined to open the dialog')

  const [accountCreationStatus, setAccountCreationStatus] = useState<StepStatus>(() => {
    return loadAccountPrivateKey(address, 0) ? 'SUCCESS' : 'UNKNOWN'
  })
  const [loginStatus, setLoginStatus] = useState<StepStatus>('UNKNOWN')

  const isDialogOpen = useCreateAccountDialogState((state) => state.isOpen)

  useEffect(() => {
    if (isDialogOpen && address) {
      setAccountCreationStatus(loadAccountPrivateKey(address, 0) ? 'SUCCESS' : 'UNKNOWN')
      setLoginStatus('UNKNOWN')
    }
    // This is only needed for wallet change flow. When we never go to disconnected state, so this component never unmount reseting itself
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we want it to react only to address change and on isDialogOpen changes to true
  }, [address, isDialogOpen])

  const loginOrRegisterStep = useCallback(async () => {
    setLoginStatus('PENDING')
    try {
      const result = await authenticate()
      if (result.isErr()) {
        setLoginStatus('UNKNOWN')
        return
      }

      setLoginStatus('SUCCESS')
      // so user can see the success state
      addDelayBeforeNextStep(() => {
        closeCreateAccountDialog()
      })
    } catch {
      setLoginStatus('UNKNOWN')
      return
    }
  }, [authenticate])

  const loginFlow = useCallback(async () => {
    const isKeysMissing = accountCreationStatus !== 'SUCCESS'
    setRememberMeSetting(rememberMe)
    if (isKeysMissing) {
      setAccountCreationStatus('PENDING')
      const res = await regenerateKey()
      if (res.isErr()) {
        setAccountCreationStatus('UNKNOWN')
        return
      }
      setAccountCreationStatus('SUCCESS')
      await loginOrRegisterStep()
    } else {
      await loginOrRegisterStep()
    }
  }, [
    accountCreationStatus,
    loginOrRegisterStep,
    regenerateKey,
    rememberMe,
    setRememberMeSetting,
  ])

  const isKnownWallet = useMemo(() => checkKnownWallet(address), [address])

  const isAnyPending = accountCreationStatus === 'PENDING' || loginStatus === 'PENDING'

  return (
    <>
      <VStack css={{ gap: 's-16' }}>
        <HStack css={{ w: '100%', justifyContent: 'space-between' }}>
          <Box css={{ textStyle: 'primary' }}>
            <FormattedMessage
              id="core.component.create-account-dialog.header.title"
              defaultMessage="Create an account"
            />
          </Box>
          <DialogCloseTrigger />
        </HStack>

        <DialogImageIcon
          src={getAssetUrl({ type: '3d-icon', name: 'account@2x' })}
          css={{ mt: 's-48' }}
        />

        <Box css={{ textStyle: 'secondary', textAlign: 'center' }}>
          <FormattedMessage
            id="core.component.create-account-dialog.step.description.title"
            defaultMessage="To start trading at X10, you will receive two signature requests:"
          />
        </Box>

        <Step.Root css={{ mt: 's-16' }}>
          <Step.Item>
            <Step.Icon status={accountCreationStatus}>1</Step.Icon>
            <Step.Content>
              <Step.Title>
                {isKnownWallet ? (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.keys-recovery.header.title"
                    defaultMessage="Keys Recovery"
                  />
                ) : (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.account-creation.header.title"
                    defaultMessage="Account creation"
                  />
                )}
              </Step.Title>

              <Step.Description>
                {isKnownWallet ? (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.keys-recovery.description.title"
                    defaultMessage="Recovering the key pair from your X10 account, which will be stored locally in the browser."
                  />
                ) : (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.account-creation.description.title"
                    defaultMessage="Generating your X10 account and signing key pair, which will be stored locally in the browser."
                  />
                )}
              </Step.Description>
            </Step.Content>
          </Step.Item>

          <Step.Item>
            <Step.Icon status={loginStatus}>2</Step.Icon>
            <Step.Content>
              <Step.Title>
                {isKnownWallet ? (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.login.header.title"
                    defaultMessage="Login"
                  />
                ) : (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.register.header.title"
                    defaultMessage="Register"
                  />
                )}
              </Step.Title>

              <Step.Description>
                {isKnownWallet ? (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.login.description.title"
                    defaultMessage="Authorizing your X10 account and enabling trading."
                  />
                ) : (
                  <FormattedMessage
                    id="core.component.create-account-dialog.step.register.description.title"
                    defaultMessage="Confirming your X10 account ownership and enabling trading."
                  />
                )}
              </Step.Description>
            </Step.Content>
          </Step.Item>
        </Step.Root>
      </VStack>
      <Box
        css={{
          w: '100%',
          mt: '2.25rem',
        }}
      >
        <Tooltip.Root
          lazyMount
          unmountOnExit
          closeDelay={100}
          openDelay={200}
          disabled={isAnyPending}
        >
          <Tooltip.Trigger>
            <Checkbox
              disabled={isAnyPending}
              checked={rememberMe}
              onCheckedChange={({ checked }) => {
                setRememberMe(checked === true)
              }}
            >
              <FormattedMessage
                id="core.component.create-account-dialog.remember-me.title"
                defaultMessage="Remember me"
              />
            </Checkbox>
          </Tooltip.Trigger>
          <Portal>
            <Tooltip.Positioner>
              <Tooltip.Content>
                <FormattedMessage
                  id="core.component.create-account-dialog.remember-me.tooltip"
                  defaultMessage="With 'Remember Me,' your stark key is stored in the browser for one week, eliminating the need to generate a new key for each session. Signatures are only required if you disconnect the wallet or clear the cache. Use this feature only on secure devices."
                />
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Portal>
        </Tooltip.Root>
        <Button
          css={{
            mt: '1.875rem',
          }}
          visual="primary-green"
          size="large"
          loading={isAnyPending}
          onClick={loginFlow}
        >
          <FormattedMessage
            id="core.component.create-account-dialog.action.send-requests.title"
            defaultMessage="Send requests"
          />
        </Button>
      </Box>
    </>
  )
}

export const CreateAccountDialog = () => {
  const isDialogOpen = useCreateAccountDialogState((state) => state.isOpen)

  return (
    <Dialog.Root
      modal
      open={isDialogOpen}
      closeOnInteractOutside={false}
      lazyMount
      closeOnEscape={false}
      onOpenChange={(isOpen) => {
        if (!isOpen.open) {
          closeCreateAccountDialog()
        }
      }}
    >
      <Portal>
        <Dialog.Backdrop />

        <Dialog.Positioner>
          <DialogContent>
            <CreateAccountDialogContent />
          </DialogContent>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
