import type { FC } from 'react'

import { styled, type HTMLStyledProps } from '@x10/lib-styled-system/jsx'
import type { SystemStyleObject } from '@x10/lib-styled-system/types'

type DialogImageProps = Omit<HTMLStyledProps<'img'>, 'css'> & {
  css?: SystemStyleObject
}

export const DialogImageIcon: FC<DialogImageProps> = ({ css, ...restProps }) => {
  return (
    <styled.img
      {...restProps}
      css={[{ w: '8rem', h: '8rem', minW: '8rem', minH: '8rem' }, css ?? {}]}
    ></styled.img>
  )
}
