import { createWalletClient, custom, getAddress, UserRejectedRequestError } from 'viem'
import {
  type AuthType,
  type Config,
  type LoginOptions,
  type ParticleNetwork,
} from '@particle-network/auth'
import type { ParticleProvider } from '@particle-network/provider'
import { createConnector } from '@wagmi/core'

export type ParticleOptions = Config

const ID_NAME_MAPPING = {
  apple: 'Apple',
  google: 'Google',
  phone: 'Phone',
  facebook: 'Facebook',
  discord: 'Discord',
  github: 'GitHub',
  twitch: 'Twitch',
  twitter: 'Twitter',
  microsoft: 'Microsoft',
  linkedin: 'LinkedIn',
  jwt: 'Jwt',
} satisfies Record<Exclude<AuthType, 'email'>, string>

const isUserRejectedRequestErrorCode = (error: unknown): error is Error => {
  return Boolean(
    error && typeof error === 'object' && 'code' in error && error.code === 4011,
  )
}

export function particle({
  id,
  options,
  loginOptions = {},
}: {
  readonly id?: AuthType
  readonly name?: string
  options: ParticleOptions
  loginOptions?: Omit<LoginOptions, 'preferredAuthType'>
}) {
  return createConnector<
    ParticleProvider,
    {
      client: ParticleNetwork | null
      provider: ParticleProvider | null
    }
  >((config) => {
    const name = id ? ID_NAME_MAPPING[id as Exclude<AuthType, 'email'>] : 'Particle'
    if (id) {
      ;(loginOptions as LoginOptions).preferredAuthType = id
    }
    return {
      id: id ?? 'particle',
      name: name,
      client: null,
      provider: null,
      type: 'Particle',
      async connect({ chainId }: { chainId?: number } = {}) {
        try {
          const provider = await this.getProvider()
          provider.on('accountsChanged', this.onAccountsChanged)
          provider.on('chainChanged', this.onChainChanged)
          provider.on('disconnect', this.onDisconnect)

          config.emitter.emit('message', { type: 'connecting' })

          // Switch to chain if provided
          let id = await this.getChainId()
          if (chainId && id !== chainId) {
            const chain = await this.switchChain!({ chainId })
            id = chain.id
          }

          if (!this.client?.auth.isLogin()) {
            await this.client?.auth.login(loginOptions)
          }
          const accounts = await this.getAccounts()

          return {
            accounts,
            chainId: id,
          }
        } catch (error) {
          if (isUserRejectedRequestErrorCode(error)) {
            throw new UserRejectedRequestError(error)
          }

          throw error
        }
      },
      async disconnect() {
        const provider = await this.getProvider()
        provider.removeListener('accountsChanged', this.onAccountsChanged)
        provider.removeListener('chainChanged', this.onChainChanged)
        provider.removeListener('disconnect', this.onDisconnect)
        await provider.disconnect()
      },
      async getAccounts() {
        const provider = await this.getProvider()
        const accounts = await provider.request({
          method: 'eth_accounts',
        })
        // return checksum address
        return [getAddress(accounts[0] as string)]
      },
      async getChainId() {
        const provider = await this.getProvider()
        const chainId = await provider.request({ method: 'eth_chainId' })
        return Number(chainId)
      },
      async getProvider() {
        if (!this.provider) {
          const [{ ParticleNetwork }, { ParticleProvider }] = await Promise.all([
            import('@particle-network/auth'),
            import('@particle-network/provider'),
          ])
          this.client = new ParticleNetwork(options)
          this.provider = new ParticleProvider(this.client.auth)
        }
        return this.provider
      },
      async getWalletClient({ chainId }: { chainId?: number } = {}) {
        const [provider, account] = await Promise.all([
          this.getProvider(),
          this.getAccounts(),
        ])
        const chain = config.chains.find((x) => x.id === chainId)!
        if (!provider) {
          throw new Error('provider is required.')
        }
        return createWalletClient({
          account: account[0],
          chain,
          transport: custom(provider),
        })
      },
      async isAuthorized() {
        try {
          await this.getProvider()
          return this.client!.auth.isLogin() && this.client!.auth.walletExist()
        } catch {
          return false
        }
      },
      async switchChain({ chainId }) {
        const provider = await this.getProvider()
        const id = `0x${chainId.toString(16)}`
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: id }],
        })
        return (
          config.chains.find((x) => x.id === chainId) ?? {
            id: chainId,
            name: `Chain ${id}`,
            network: `${id}`,
            nativeCurrency: { name: 'Ether', decimals: 18, symbol: 'ETH' },
            rpcUrls: { default: { http: [''] }, public: { http: [''] } },
          }
        )
      },
      onAccountsChanged(accounts) {
        if (accounts.length === 0) {
          config.emitter.emit('disconnect')
        } else {
          config.emitter.emit('change', {
            accounts: accounts.map((it) => getAddress(it)),
          })
        }
      },
      onChainChanged(chainId) {
        const id = Number(chainId)
        config.emitter.emit('change', { chainId: id })
      },
      async onDisconnect() {
        config.emitter.emit('disconnect')
      },
    }
  })
}
