import { useState } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { type Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { Button, Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'

export const NotificationPendingDepositReclaim = ({
  amount,
  onClaimClick,
}: {
  amount: Decimal | number
  onClaimClick: () => Promise<unknown>
}) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: false })
  const [isPending, setIsPending] = useState(false)
  return (
    <Toast.Root
      css={{
        flexDirection: 'column',
        gap: 's-16',
      }}
    >
      <Box
        css={{
          w: '100%',
          display: 'flex',
          gap: 's-16',
          justifyContent: 'center',
          alignItems: 'center',
          "&:hover button[data-part='close-trigger']": {
            display: 'block',
          },
        }}
      >
        <Notification.Avatar
          css={{
            bg: 'token.green',
          }}
        >
          <SvgIcon.SvgIconCheck />
        </Notification.Avatar>

        <Notification.Content>
          <Notification.TitleAndSide>
            <Notification.Title>
              <FormattedMessage
                id="core.component.notification.claim-rejected-deposit.title"
                defaultMessage="Claim Rejected Deposit"
              />
            </Notification.Title>

            <Notification.Side>
              <Notification.Value>
                {formatMarketAsset(
                  {
                    amount: amount,
                    type: 'collateral',
                  },
                  {
                    precision: AssetPrecision.AsIs,
                  },
                )}{' '}
                USDC
              </Notification.Value>
            </Notification.Side>
          </Notification.TitleAndSide>
        </Notification.Content>
      </Box>
      <Toast.Context>
        {({ api: { dismiss } }) => (
          <HStack
            css={{
              width: '100%',
              gap: 's-16',
              mt: 's-8',
            }}
          >
            <Button
              visual="primary-green"
              loading={isPending}
              onClick={() => {
                setIsPending(true)
                onClaimClick().then(() => {
                  dismiss()
                })
              }}
            >
              <FormattedMessage id="common.action.claim" defaultMessage="Claim" />
            </Button>
            <Button visual="secondary-grey" loading={isPending} onClick={() => dismiss()}>
              <FormattedMessage id="common.action.hide" defaultMessage="Hide" />
            </Button>
          </HStack>
        )}
      </Toast.Context>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
