import type { FC, PropsWithChildren } from 'react'

import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import type { FeatureName } from '@src/domain/core/store/features'

type FeatureGateProps = PropsWithChildren<{
  name: FeatureName
}>

export const FeatureGate: FC<FeatureGateProps> = ({ name, children }) => {
  const isFeatureEnabled = useIsFeatureEnabled()

  return isFeatureEnabled(name) ? children : null
}
