import { isNull } from 'lodash'

import { getStorageKey } from './get-storage-key'
import type { SessionStorageKey } from './types'

export const appSessionStorage = {
  getItem<T = unknown>(key: SessionStorageKey) {
    try {
      const raw = sessionStorage.getItem(getStorageKey(key))

      if (isNull(raw)) {
        return raw
      }

      return JSON.parse(raw).payload as T
    } catch (e) {
      return null
    }
  },

  setItem(key: SessionStorageKey, payload: unknown) {
    try {
      sessionStorage.setItem(getStorageKey(key), JSON.stringify({ payload }))

      return true
    } catch (e) {
      return false
    }
  },

  removeItem(key: SessionStorageKey) {
    try {
      sessionStorage.removeItem(getStorageKey(key))

      return true
    } catch (e) {
      return false
    }
  },

  clear() {
    sessionStorage.clear()
  },
}
