import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@src/domain/api/x10'
import * as intercom from '@src/domain/core/utils/intercom'
import { QUERIES_TO_DROP_ON_LOGOUT } from '@src/domain/trade/constants'

export const useAuthLogout = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: API.auth.logout,
    onSuccess: async () => {
      intercom.reset()

      await queryClient.invalidateQueries({
        queryKey: QUERIES_TO_DROP_ON_LOGOUT,
      })
    },
  })
}
