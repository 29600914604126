import { useClientSettingsStore } from '@src/domain/core/store/user-settings'

import type { Notification } from './notification-toast'

export const checkIfNotificationAllowed = (notification: Notification) => {
  const allowedNotifications = useClientSettingsStore.getState().allowedNotifications

  if (!allowedNotifications) {
    return true
  }

  switch (notification.type) {
    case 'ORDER_EDIT_CANCELLED':
    case 'ORDER_EDIT_REJECTED':
    case 'TRADE':
    case 'TRADE_WITH_AMOUNTS':
    case 'TRADE_PARTIALLY_FILLED':
    case 'TRADE_TPSL':
      return allowedNotifications.includes('ORDERS_AND_TRADES')

    case 'LIQUIDATION':
    case 'LIQUIDATION_ADL_OR_LIQUIDATED':
    case 'LIQUIDATION_MARGIN_CALL_1':
    case 'LIQUIDATION_MARGIN_CALL_2':
    case 'LIQUIDATION_NEGATIVE_BALANCE':
      return allowedNotifications.includes('LIQUIDATIONS')

    case 'ACCOUNT_CREATED':
    case 'DEPOSIT_COMPLETED':
    case 'DEPOSIT_DETECTED':
    case 'DEPOSIT_REJECTED':
    case 'DEPOSITS_ENABLED':
    case 'LEVERAGE':
    case 'PENDING_WITHDRAWAL':
    case 'RECLAIM_PENDING_DEPOSIT':
    case 'TRANSFER':
    case 'WITHDRAW':
      return allowedNotifications.includes('TX_AND_ACCOUNTS')

    case 'COMPETITION':
    case 'COPY_ADDRESS':
      return true
  }
}
