export const INTERCOM_CHAT_BUTTON_ID = 'intercom-chat-button'

// Loads Intercom with the snippet
// This must be run before boot, it initializes window.Intercom
const loadScript = () => {
  // prettier-ignore
  // @ts-expect-error attachEvent is not defined in window
  // eslint-disable-next-line
  ;(function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;const i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/qf4pu57c';const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

const withIntercom = (fn: (Intercom: Intercom_.IntercomStatic) => void) => {
  if (window && window.Intercom) {
    fn(window.Intercom)
  }
}

export const load = (intercomAppId: string) => {
  window.intercomSettings = {
    app_id: intercomAppId,
  }

  loadScript()
}

export const update = () => {
  withIntercom((Intercom) => {
    Intercom('update')
  })
}

// Initializes Intercom
export const boot = (options: Intercom_.IntercomSettings = {}) => {
  withIntercom((Intercom) => {
    Intercom('boot', {
      ...window.intercomSettings,
      custom_launcher_selector: `#${INTERCOM_CHAT_BUTTON_ID}`,
      ...options,
    })

    update()
  })
}

export const reset = () => {
  withIntercom((Intercom) => {
    Intercom('shutdown')
    boot()
  })
}
