import { type FC } from 'react'

import type { CryptoCurrencySyntheticCode } from '@x10/lib-core/config'
import { FormattedMessage, useIntl, type MessageDescriptor } from '@x10/lib-core/i18n'
import { getAssetUrl, notReachable, type Decimal } from '@x10/lib-core/utils'
import { Logo, Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import type { MarketName, OrderType } from '@src/domain/api/x10/common'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'

const getTradeMessages = (event: TradeEventsWithAmounts): MessageDescriptor => {
  switch (event) {
    case 'filled':
      return {
        id: 'core.component.notification.order.filled.title',
        defaultMessage: '{formattedAsset} {direction} at price ${formattedCollateral}.',
      }
    case 'expired':
      return {
        id: 'core.component.notification.order.expired.message',
        defaultMessage:
          '{limitOrMarket} {direction} order for {formattedAsset} at price ${formattedCollateral} expired.',
      }
    default:
      return notReachable(event)
  }
}

const getPresentOrPastFormMessage = (
  event: TradeEventsWithAmounts,
  direction: 'BUY' | 'SELL',
): MessageDescriptor => {
  switch (event) {
    case 'expired':
      if (direction === 'BUY') {
        return {
          id: 'core.component.notification.order.direction.buy',
          defaultMessage: 'buy',
        }
      }
      return {
        id: 'core.component.notification.order.direction.sell',
        defaultMessage: 'sell',
      }
    case 'filled':
      if (direction === 'BUY') {
        return {
          id: 'core.component.notification.order.direction.buy.past',
          defaultMessage: 'bought',
        }
      }
      return {
        id: 'core.component.notification.order.direction.sell.past',
        defaultMessage: 'sold',
      }
    default:
      return notReachable(event)
  }
}

export type TradeEventsWithAmounts = 'filled' | 'expired'

const eventToIconMapping = (
  event: TradeEventsWithAmounts,
  code: CryptoCurrencySyntheticCode,
) => {
  switch (event) {
    case 'filled':
      return (
        <Logo
          url={getAssetUrl({
            type: 'crypto',
            name: code,
          })}
          boxSize={40}
        />
      )
    case 'expired':
      return <SvgIcon.SvgIconCross />
    default:
      return notReachable(event)
  }
}

const eventToBgColorMapping = (event: TradeEventsWithAmounts): string => {
  switch (event) {
    case 'filled':
      return 'token.green'
    case 'expired':
      return 'token.red'
    default:
      return notReachable(event)
  }
}

export const NotificationTradeWithAmounts: FC<{
  collateralPrice: Decimal
  syntheticAmount: Decimal
  direction: 'BUY' | 'SELL'
  event: TradeEventsWithAmounts
  marketName: MarketName
  orderType: OrderType
}> = ({ collateralPrice, syntheticAmount, direction, event, marketName, orderType }) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true, marketName })
  const message = getTradeMessages(event)
  const { formatMessage } = useIntl()
  const getCachedMarket = useGetCachedMarket()

  const { assets } = getCachedMarket(marketName)
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: eventToBgColorMapping(event),
        }}
      >
        {eventToIconMapping(event, assets.synthetic.code)}
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              {...message}
              values={{
                formattedAsset: formatMarketAsset(
                  {
                    amount: syntheticAmount,
                    type: 'synthetic',
                  },
                  { precision: AssetPrecision.AsIs },
                ),
                formattedCollateral: formatMarketAsset(
                  {
                    amount: collateralPrice,
                    type: 'collateral',
                  },
                  { showSymbol: false, precision: AssetPrecision.AsIs },
                ),
                direction: formatMessage(getPresentOrPastFormMessage(event, direction)),
                limitOrMarket:
                  orderType === 'MARKET'
                    ? formatMessage({
                        id: 'core.component.notification.order.expired.market',
                        defaultMessage: 'Market',
                      })
                    : formatMessage({
                        id: 'core.component.notification.order.expired.limit',
                        defaultMessage: 'Limit',
                      }),
              }}
            />
          </Notification.Title>
        </Toast.Title>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
