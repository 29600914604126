import { type FC } from 'react'

import { FormattedMessage, useIntl, type MessageDescriptor } from '@x10/lib-core/i18n'
import { getAssetUrl, notReachable, type Decimal } from '@x10/lib-core/utils'
import { Logo, Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'

const getDirectionMessage = (direction: 'BUY' | 'SELL'): MessageDescriptor => {
  if (direction === 'BUY') {
    return {
      id: 'core.component.notification.order.direction.buy.past',
      defaultMessage: 'bought',
    }
  }
  return {
    id: 'core.component.notification.order.direction.sell.past',
    defaultMessage: 'sold',
  }
}

const getOverridedIcon = (icon: 'red-cross' | 'orange-triangle') => {
  switch (icon) {
    case 'red-cross':
      return <SvgIcon.SvgIconCross />
    case 'orange-triangle':
      return <SvgIcon.SvgIconExclamationTriangle />
    default:
      return notReachable(icon)
  }
}

export const NotificationTradePartiallyFilled: FC<{
  collateralAveragePrice: Decimal
  syntheticAmount: Decimal
  syntheticFilledAmount: Decimal
  direction: 'BUY' | 'SELL'
  overrideIcon?: 'red-cross' | 'orange-triangle'
  marketName: MarketName
}> = ({
  collateralAveragePrice,
  syntheticAmount,
  syntheticFilledAmount,
  direction,
  marketName,
  overrideIcon,
}) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true, marketName })
  const { formatMessage } = useIntl()
  const getCachedMarket = useGetCachedMarket()

  const { assets } = getCachedMarket(marketName)

  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: overrideIcon === 'red-cross' ? 'token.red' : 'token.orange',
        }}
      >
        {overrideIcon ? (
          getOverridedIcon(overrideIcon)
        ) : (
          <Logo
            url={getAssetUrl({
              type: 'crypto',
              name: assets.synthetic.code,
            })}
            boxSize={40}
          />
        )}
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id="core.component.notification.order.partially-filled.title"
              defaultMessage="{formattedAsset} were {direction} at average price ${formattedCollateral}."
              values={{
                formattedAsset: `${formatMarketAsset(
                  {
                    amount: syntheticFilledAmount,
                    type: 'synthetic',
                  },
                  { showSymbol: false, precision: AssetPrecision.AsIs },
                )}/${formatMarketAsset(
                  {
                    amount: syntheticAmount,
                    type: 'synthetic',
                  },
                  {
                    precision: AssetPrecision.AsIs,
                  },
                )}`,
                direction: formatMessage(getDirectionMessage(direction)),
                formattedCollateral: formatMarketAsset(
                  {
                    amount: collateralAveragePrice,
                    type: 'collateral',
                  },
                  { showSymbol: false, precision: AssetPrecision.AsIs },
                ),
              }}
            />
          </Notification.Title>
        </Toast.Title>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
