import { type FC } from 'react'

import { FormattedMessage, type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import { ApiErrorCode } from '@src/domain/core/errors/base'
import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'

const getReasonIntlMessage = (
  reason: LeverageChangeRejectedReason | undefined,
): MessageDescriptor | undefined => {
  switch (reason) {
    case 'leverage-exceeds-max':
      return {
        id: 'core.component.notification.trade.reason.leverage-exceeds-max',
        defaultMessage: 'Selected leverage exceeds the maximum allowable leverage.',
      }
    case 'max-position-value-exceeded':
      return {
        id: 'core.component.notification.trade.reason.max-position-value-exceeded',
        defaultMessage: 'Maximum position value exceeded for the selected leverage.',
      }
    case 'insufficient-margin':
      return {
        id: 'core.component.notification.trade.reason.insufficient-margin',
        defaultMessage:
          'Insufficient available balance to support the selected leverage.',
      }
    case undefined:
      return undefined
    default:
      return notReachable(reason)
  }
}

export type LeverageChangeRejectedReason =
  | 'leverage-exceeds-max'
  | 'max-position-value-exceeded'
  | 'insufficient-margin'

export const ERRORS_TO_REJECTION_REASONS: Record<number, LeverageChangeRejectedReason> = {
  [ApiErrorCode.LeverageExceedsMaxLeverage]: 'leverage-exceeds-max',
  [ApiErrorCode.LeverageMaxPositionValueExceeded]: 'max-position-value-exceeded',
  [ApiErrorCode.LeverageInsufficientMargin]: 'insufficient-margin',
}

export const getRejectionReasonByCode = (
  errorCode: number | undefined,
): LeverageChangeRejectedReason | undefined => {
  const reason = errorCode ? ERRORS_TO_REJECTION_REASONS[errorCode] : undefined

  if (!reason) {
    // if errorCode is DEFINED but not in the mapping, we want to know about it
    errorCode &&
      captureExceptionWithSentry(
        new Error(`Unknown leverage change rejection reason: ${errorCode}`),
      )

    return undefined
  }

  return reason
}

export type LeverageNotificationKinds = 'rejected' | 'modified'

const typeToIconMapping = (type: LeverageNotificationKinds) => {
  switch (type) {
    case 'modified':
      return <SvgIcon.SvgIconCheck />
    case 'rejected':
      return <SvgIcon.SvgIconCross />
    default:
      return notReachable(type)
  }
}

const getIntlMessageByType = (type: LeverageNotificationKinds): MessageDescriptor => {
  switch (type) {
    case 'modified':
      return {
        id: 'core.component.notification.trade.title.leverage-modified',
        defaultMessage: 'Leverage modified',
      }
    case 'rejected':
      return {
        id: 'core.component.notification.trade.title.leverage-rejected',
        defaultMessage: 'Leverage change rejected',
      }
    default:
      return notReachable(type)
  }
}

export const NotificationLeverage: FC<{
  kind: LeverageNotificationKinds
  reasonCode?: number
}> = ({ kind, reasonCode }) => {
  const rejectionReason = getRejectionReasonByCode(reasonCode)

  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: kind === 'modified' ? 'token.green' : 'token.red',
        }}
      >
        {typeToIconMapping(kind)}
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage {...getIntlMessageByType(kind)} />
          </Notification.Title>
        </Toast.Title>

        {kind === 'rejected' && (
          <Notification.Description>
            <FormattedMessage {...getReasonIntlMessage(rejectionReason)} />
          </Notification.Description>
        )}
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
