import { type FC } from 'react'

import { type Decimal } from '@x10/lib-core/utils'
import { type ToasterProps } from '@x10/lib-ui-kit/components'

import type { MarketName, OrderType } from '@src/domain/api/x10/common'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { toaster } from '@src/domain/core/providers/app-providers/toaster'

import { NotificationAccountCreated } from './notification-account-created'
import { NotificationCompetition } from './notification-competition'
import { NotificationCopyAddress } from './notification-copy-address'
import { NotificationDepositCompleted } from './notification-deposit-completed'
import { NotificationDepositDetected } from './notification-deposit-detected'
import { NotificationDepositRejected } from './notification-deposit-rejected'
import { NotificationDepositsEnabled } from './notification-deposits-enabled'
import { NotificationLeverage } from './notification-leverage'
import { NotificationLiquidation } from './notification-liquidation'
import { NotificationLiquidationAdlOrLiquidated } from './notification-liquidation-adl-or-liquidated'
import { NotificationLiquidationMarginCall } from './notification-liquidation-margin-call'
import { NotificationLiquidationNegativeBalance } from './notification-liquidation-negative-balance'
import { NotificationOrderEditCancelled } from './notification-order-edit-cancelled'
import { NotificationOrderEditReject } from './notification-order-edit-reject'
import { NotificationPendingDepositReclaim } from './notification-pending-deposit-reclaim'
import { NotificationPendingWithdrawal } from './notification-pending-withdrawal'
import { NotificationTradePartiallyFilled } from './notification-trade-partially-filled'
import {
  NotificationTradeSimple,
  type SimpleTradeEvents,
} from './notification-trade-simple'
import { NotificationTradeTpsl, type TpslTradeEvents } from './notification-trade-tpsl'
import {
  NotificationTradeWithAmounts,
  type TradeEventsWithAmounts,
} from './notification-trade-with-amounts'
import { NotificationTransfer } from './notification-transfer'
import { NotificationWithdraw } from './notification-withdraw'
import { checkIfNotificationAllowed } from './utils'

type NotificationToastProps = {
  notification: Notification
}

const NotificationToast: FC<NotificationToastProps> = ({ notification }) => {
  switch (notification.type) {
    case 'TRANSFER':
      return <NotificationTransfer status={notification.status} />
    case 'ACCOUNT_CREATED':
      return <NotificationAccountCreated />
    case 'TRADE_WITH_AMOUNTS':
      return (
        <NotificationTradeWithAmounts
          collateralPrice={notification.collateralPrice}
          syntheticAmount={notification.syntheticAmount}
          direction={notification.direction}
          event={notification.event}
          marketName={notification.marketName}
          orderType={notification.orderType}
        />
      )
    case 'TRADE':
      return (
        <NotificationTradeSimple
          event={notification.event}
          marketName={notification.marketName}
          reason={notification.reason}
        />
      )
    case 'TRADE_PARTIALLY_FILLED':
      return (
        <NotificationTradePartiallyFilled
          collateralAveragePrice={notification.collateralAveragePrice}
          syntheticAmount={notification.syntheticAmount}
          syntheticFilledAmount={notification.syntheticFilledAmount}
          direction={notification.direction}
          marketName={notification.marketName}
          overrideIcon={notification.overrideIcon}
        />
      )
    case 'TRADE_TPSL':
      return (
        <NotificationTradeTpsl
          marketName={notification.marketName}
          tpsl={notification.tpsl}
          event={notification.event}
        />
      )
    case 'ORDER_EDIT_CANCELLED':
      return (
        <NotificationOrderEditCancelled
          syntheticAmount={notification.syntheticAmount}
          direction={notification.direction}
          marketName={notification.marketName}
          orderType={notification.orderType}
          reason={notification.reason}
        />
      )
    case 'ORDER_EDIT_REJECTED':
      return <NotificationOrderEditReject reason={notification.reason} />
    case 'DEPOSIT_DETECTED':
      return <NotificationDepositDetected amount={notification.amount} />
    case 'DEPOSIT_REJECTED':
      return <NotificationDepositRejected amount={notification.amount} />
    case 'DEPOSIT_COMPLETED':
      return <NotificationDepositCompleted amount={notification.amount} />
    case 'DEPOSITS_ENABLED':
      return <NotificationDepositsEnabled />
    case 'WITHDRAW':
      return (
        <NotificationWithdraw
          duration={notification.duration}
          state={notification.state}
          amount={notification.amount}
        />
      )
    case 'COPY_ADDRESS':
      return <NotificationCopyAddress />
    case 'LIQUIDATION':
      return <NotificationLiquidation />
    case 'LIQUIDATION_ADL_OR_LIQUIDATED':
      return (
        <NotificationLiquidationAdlOrLiquidated
          collateralAmount={notification.collateralAmount}
          syntheticAmount={notification.syntheticAmount}
          direction={notification.direction}
          marketName={notification.marketName}
          kind={notification.kind}
        />
      )
    case 'LIQUIDATION_MARGIN_CALL_1':
      return <NotificationLiquidationMarginCall callNumber={1} />
    case 'LIQUIDATION_MARGIN_CALL_2':
      return <NotificationLiquidationMarginCall callNumber={2} />
    case 'LIQUIDATION_NEGATIVE_BALANCE':
      return <NotificationLiquidationNegativeBalance />
    case 'COMPETITION':
      return <NotificationCompetition onClose={notification.onClose} />
    case 'PENDING_WITHDRAWAL':
      return (
        <NotificationPendingWithdrawal
          amount={notification.amount}
          onClaimClick={notification.onClaimClick}
        />
      )
    case 'RECLAIM_PENDING_DEPOSIT':
      return (
        <NotificationPendingDepositReclaim
          amount={notification.amount}
          onClaimClick={notification.onClaimClick}
        />
      )
    case 'LEVERAGE':
      return (
        <NotificationLeverage
          kind={notification.kind}
          reasonCode={notification.reasonCode}
        />
      )
  }
}

export type Notification =
  | {
      type: 'TRADE_WITH_AMOUNTS'
      collateralPrice: Decimal
      syntheticAmount: Decimal
      direction: 'BUY' | 'SELL'
      event: TradeEventsWithAmounts
      marketName: MarketName
      orderType: OrderType
    }
  | {
      type: 'TRADE'
      event: SimpleTradeEvents
      marketName: MarketName
      reason?: string | number
    }
  | {
      type: 'TRADE_PARTIALLY_FILLED'
      collateralAveragePrice: Decimal
      syntheticAmount: Decimal
      syntheticFilledAmount: Decimal
      direction: 'BUY' | 'SELL'
      marketName: MarketName
      overrideIcon?: 'red-cross' | 'orange-triangle'
    }
  | {
      type: 'TRADE_TPSL'
      marketName: MarketName
      tpsl: 'tp' | 'sl' | 'tpsl'
      event: TpslTradeEvents
    }
  | {
      type: 'ORDER_EDIT_CANCELLED'
      syntheticAmount: Decimal
      direction: 'BUY' | 'SELL'
      marketName: MarketName
      orderType: OrderType
      reason?: string | number
    }
  | { type: 'ORDER_EDIT_REJECTED'; reason?: string | number }
  | { type: 'DEPOSIT_DETECTED'; amount: number | Decimal }
  | { type: 'DEPOSIT_REJECTED'; amount: number | Decimal }
  | { type: 'DEPOSIT_COMPLETED'; amount: number | Decimal }
  | { type: 'DEPOSITS_ENABLED' }
  | {
      type: 'LIQUIDATION_ADL_OR_LIQUIDATED'
      syntheticAmount: Decimal
      direction: 'BUY' | 'SELL'
      collateralAmount: Decimal
      marketName: MarketName
      kind: 'adled' | 'liquidated'
    }
  | { type: 'LIQUIDATION' }
  | { type: 'LIQUIDATION_MARGIN_CALL_1' }
  | { type: 'LIQUIDATION_MARGIN_CALL_2' }
  | { type: 'LIQUIDATION_NEGATIVE_BALANCE' }
  | {
      type: 'WITHDRAW'
      state: 'created' | 'completed' | 'failed'
      amount: Decimal | number
      duration?: string
    }
  | { type: 'COPY_ADDRESS' }
  | { type: 'ACCOUNT_CREATED' }
  | {
      type: 'TRANSFER'
      status: 'success' | 'failed'
    }
  | { type: 'COMPETITION'; onClose: VoidFunction }
  | {
      type: 'PENDING_WITHDRAWAL'
      amount: Decimal | number
      onClaimClick: () => Promise<unknown>
    }
  | {
      type: 'RECLAIM_PENDING_DEPOSIT'
      amount: Decimal | number
      onClaimClick: () => Promise<unknown>
    }
  | {
      type: 'LEVERAGE'
      kind: 'modified' | 'rejected'
      reasonCode?: number
    }

export const GlobalToastIds = {
  PendingWithdrawal: 'pending-withdrawal',
  ReclaimPendingDeposit: 'reclaim-pending-deposit',
}

export const createNotificationToast = ({
  id,
  notification,
  duration,
}: {
  id?: string
  duration?: number
  notification: Notification
}) => {
  if (!checkIfNotificationAllowed(notification)) {
    return
  }

  return toaster.create({
    id,
    type: 'custom',
    duration: duration ?? 4_000,
    meta: { notification },
  })
}

export const removeNotificationToast = (id: string) => {
  toaster.remove(id)
}

export const dismissNotificationToast = (id: string) => {
  toaster.dismiss(id)
}

export const notificationToastRender: ToasterProps['children'] = (toast) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isFeatureEnabled = useIsFeatureEnabled()

  if (isFeatureEnabled('HIDE_NOTIFICATIONS')) {
    return null
  }

  return (
    <NotificationToast
      notification={toast.meta?.notification as Notification}
      {...toast}
    />
  )
}
