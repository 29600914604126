import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'

type NotificationDepositProps = {
  amount: number | Decimal
}

export const NotificationDepositDetected: FC<NotificationDepositProps> = ({ amount }) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: false })

  return (
    <Toast.Root>
      <Notification.Avatar>
        <SvgIcon.SvgIconTime />
      </Notification.Avatar>

      <Notification.Content>
        <Notification.TitleAndSide>
          <Toast.Title asChild>
            <Notification.Title>
              <FormattedMessage
                id="core.component.notification.deposit.detected.title"
                defaultMessage="Deposit Detected"
              />
            </Notification.Title>
          </Toast.Title>
          <Notification.Side>
            <Notification.Value>
              +
              {formatMarketAsset(
                {
                  amount: amount,
                  type: 'collateral',
                },
                { precision: AssetPrecision.AsIs },
              )}{' '}
              USDC
            </Notification.Value>
          </Notification.Side>
        </Notification.TitleAndSide>
        <Notification.Description>
          <FormattedMessage
            id="core.component.notification.deposit.detected.arrival-time.title"
            defaultMessage="Arrival time"
          />
          &nbsp;
          <FormattedMessage
            id="core.component.notification.deposit.detected.arrival-time.eta.title"
            defaultMessage="2 minutes"
          />
        </Notification.Description>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
