import { useSuspenseQuery } from '@tanstack/react-query'

import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API, type AccountInfo } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

const compareAccounts = (valueA: AccountInfo, valueB: AccountInfo) => {
  if (valueA.accountIndex === 0) {
    return -1
  }

  if (valueB.accountIndex === 0) {
    return 1
  }

  return valueA.description.localeCompare(valueB.description)
}

export const useAccounts = () => {
  const accountId = useAccountId()

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.UserAccountsInfo, accountId],
    queryFn: () => API.trading.account.getAccounts(accountId),

    select: (data) => {
      data.data.sort(compareAccounts)

      return data
    },
  })

  return removeRestEnvelopeSuspense(data)
}
