import type { FC } from 'react'

import { ActionIcon, Dialog, SvgIcon } from '@x10/lib-ui-kit/components'

export const DialogCloseTrigger: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <Dialog.CloseTrigger css={{ alignSelf: 'end' }} asChild onClick={onClick}>
      <ActionIcon>
        <SvgIcon.SvgIconCross />
      </ActionIcon>
    </Dialog.CloseTrigger>
  )
}
