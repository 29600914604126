import { type FC } from 'react'

import { FormattedMessage, type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import { ApiErrorCode } from '@src/domain/core/errors/base'
import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'

const getReasonMessage = (
  reason: OrderEditRejectionReasons | undefined,
): MessageDescriptor | undefined => {
  switch (reason) {
    case 'order-price-invalid':
      return {
        id: 'core.component.notification.order.edit-rejected.reason.order-price-invalid',
        defaultMessage: 'New order price is invalid.',
      }
    case 'order-size-invalid':
      return {
        id: 'core.component.notification.order.edit-rejected.reason.order-size-invalid',
        defaultMessage: 'New order size is invalid.',
      }
    case 'reduce-only-order-size':
      return {
        id: 'core.component.notification.order.edit-rejected.reason.reduce-only-order-size',
        defaultMessage:
          'Reduce-only order size must be less than the open position minus open reducing orders.',
      }
    case 'maximum-position-value-exceeded':
      return {
        id: 'core.component.notification.order.edit-rejected.reason.maximum-position-value-exceeded',
        defaultMessage:
          'If executed, the updated order will exceed the maximum position value.',
      }
    case 'insufficient-balance':
      return {
        id: 'core.component.notification.order.edit-rejected.reason.insufficient-balance',
        defaultMessage: 'Your available balance is insufficient to update the order.',
      }
    case 'contradict-post-only':
      return {
        id: 'core.component.notification.order.edit-rejected.reason.contradict-post-only',
        defaultMessage:
          'If placed, the updated order would contradict the post-only requirement.',
      }
    case 'execution-price-exceeds-5':
      return {
        id: 'core.component.notification.order.edit-rejected.reason.execution-price-exceeds-5',
        defaultMessage: 'There is no liquidity at the new price.',
      }
    case undefined:
      return undefined
    default:
      return notReachable(reason)
  }
}

export type OrderEditRejectionReasons =
  | 'order-price-invalid'
  | 'order-size-invalid'
  | 'reduce-only-order-size'
  | 'maximum-position-value-exceeded'
  | 'insufficient-balance'
  | 'contradict-post-only'
  | 'execution-price-exceeds-5'

const ERRORS_TO_EDIT_REJECTION_REASONS: Record<string, OrderEditRejectionReasons> = {
  [ApiErrorCode.OrderQtyLessThanMinTradeSize]: 'order-size-invalid',
  [ApiErrorCode.InvalidQtyWrongSizeIncrement]: 'order-size-invalid',
  [ApiErrorCode.OrderValueExceedsMaxOrderValue]: 'order-size-invalid',
  [ApiErrorCode.InvalidQtyPrecision]: 'order-size-invalid',
  [ApiErrorCode.InvalidPriceWrongPriceMovement]: 'order-price-invalid',
  [ApiErrorCode.InvalidPricePrecision]: 'order-price-invalid',
  [ApiErrorCode.MaxPositionValueExceeded]: 'maximum-position-value-exceeded',
  [ApiErrorCode.InvalidPositionTpslQty]: 'order-size-invalid',
  [ApiErrorCode.MissingOrderPrice]: 'order-price-invalid',
  [ApiErrorCode.ReduceOnlyOrderSizeExceedsPositionSize]: 'reduce-only-order-size',
  [ApiErrorCode.OrderCostExceedsBalance]: 'insufficient-balance',
  [ApiErrorCode.InvalidPriceAmount]: 'order-price-invalid',

  // Subset of rejection/cancellation reasons from X10 matching engine:
  // https://github.com/x10xchange/matching-engine-v2/blob/main/src/main/kotlin/exchange/x10/matching/model/order/OrderStatusReason.kt
  INVALID_PRICE: 'order-price-invalid',
  INVALID_QTY: 'order-size-invalid',
  NO_LIQUIDITY: 'execution-price-exceeds-5',
  POST_ONLY_FAILED: 'contradict-post-only',
  REDUCE_ONLY_FAILED: 'reduce-only-order-size',
}

const getOrderEditRejectionReason = (
  error: string | number | undefined,
): OrderEditRejectionReasons | undefined => {
  const reason = error ? ERRORS_TO_EDIT_REJECTION_REASONS[error] : undefined

  if (!reason) {
    // if error is DEFINED but not in the mapping, we want to know about it
    error &&
      captureExceptionWithSentry(new Error(`Unknown trade rejection reason: ${error}`))

    return undefined
  }

  return reason
}

export const NotificationOrderEditReject: FC<{
  reason?: string | number
}> = ({ reason }) => {
  const reasonMessage = getReasonMessage(getOrderEditRejectionReason(reason))
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.orange',
        }}
      >
        <SvgIcon.SvgIconCross />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id="core.component.notification.order.edit-rejected.title"
              defaultMessage="Order edit rejected"
            />
          </Notification.Title>
        </Toast.Title>

        {reasonMessage && (
          <Toast.Description asChild>
            <Notification.Description>
              <FormattedMessage {...reasonMessage} />
            </Notification.Description>
          </Toast.Description>
        )}
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
