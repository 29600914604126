import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'

export const NotificationLiquidationAdlOrLiquidated = ({
  syntheticAmount,
  direction,
  collateralAmount,
  marketName,
  kind,
}: {
  syntheticAmount: Decimal
  direction: 'BUY' | 'SELL'
  collateralAmount: Decimal
  marketName: MarketName
  kind: 'liquidated' | 'adled'
}) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true, marketName })
  const { formatMessage } = useIntl()

  const formattedSynthetic = formatMarketAsset(
    {
      amount: syntheticAmount,
      type: 'synthetic',
    },
    { precision: AssetPrecision.AsIs },
  )

  const directionMsg =
    direction === 'BUY'
      ? formatMessage({
          id: `core.component.notification.liquidation.direction.buy`,
          defaultMessage: direction,
        })
      : formatMessage({
          id: `core.component.notification.liquidation.direction.sell`,
          defaultMessage: direction,
        })

  const formattedCollateral = formatMarketAsset(
    {
      amount: collateralAmount,
      type: 'collateral',
    },
    { showSymbol: false, precision: AssetPrecision.AsIs },
  )

  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.red',
        }}
      >
        <SvgIcon.SvgIconExclamationTriangle />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            {kind === 'adled' ? (
              <FormattedMessage
                id="core.component.notification.liquidation.adl.title"
                defaultMessage="{formattedSynthetic} {direction} position ADL'ed at price ${formattedCollateral}."
                values={{
                  formattedSynthetic,
                  direction: directionMsg,
                  formattedCollateral,
                }}
              />
            ) : (
              <FormattedMessage
                id="core.component.notification.liquidation.position-liquidated.title"
                defaultMessage="{formattedSynthetic} {direction} position liquidated at price ${formattedCollateral}."
                values={{
                  formattedSynthetic,
                  direction: directionMsg,
                  formattedCollateral,
                }}
              />
            )}
          </Notification.Title>
        </Toast.Title>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
