import type { FC, PropsWithChildren } from 'react'
import { createConfig, http, WagmiConfig, type CreateConnectorFn } from 'wagmi'
import { mainnet, sepolia } from 'wagmi/chains'
import { coinbaseWallet, injected, walletConnect } from '@wagmi/connectors'

import { getClientConfig } from '@src/domain/core/config/env'
import {
  PARTICLE_APP_ID,
  PARTICLE_CLIENT_KEY,
  PARTICLE_PROJECT_ID,
  WALLET_CONNECT_PROJECT_ID,
} from '@src/domain/core/config/static'

import { particle, type ParticleOptions } from './particle'

export type Web2ConnectorId = 'particle' | 'google' | 'apple'

export type Web3ConnectorId =
  | 'coinbaseWalletSDK'
  | 'injected'
  | 'walletConnect'
  | 'avalanche'
  | 'tally'
  | 'portal'
  | 'tokenary'
  | 'xDEFI'
  | 'status'
  | 'oneInchAndroidWallet'
  | 'oneInchIOSWallet'
  | 'mathWallet'
  | 'kuCoinWallet'
  | 'imToken'
  | 'hyperPay'
  | 'frontier'
  | 'frame'
  | 'dawn'
  | 'blockWallet'
  | 'bitski'
  | 'bifrost'
  | 'backpack'
  | 'opera'

export type ConnectorId = Web3ConnectorId | Web2ConnectorId

const particleOptions: ParticleOptions = {
  projectId: PARTICLE_PROJECT_ID,
  clientKey: PARTICLE_CLIENT_KEY,
  appId: PARTICLE_APP_ID,
}

export const getChain = () => (getClientConfig().isProdRuntimeEnv ? mainnet : sepolia)

// FIXME: Disabled for all envs but prod, as wallet connect is unstable on Sepolia see:
// - https://linear.app/ex10/issue/X10-2213/hide-walletconnect-option-because-it-is-unstable
// - https://linear.app/ex10/issue/X10-1633/walletconnect-seems-to-not-be-working
const PROD_CONNECTORS: CreateConnectorFn[] = getClientConfig().isProdRuntimeEnv
  ? [
      walletConnect({
        projectId: WALLET_CONNECT_PROJECT_ID,
      }),
    ]
  : []

export const wagmiConfig = createConfig({
  chains: [getChain()],
  ssr: typeof window === 'undefined',
  connectors: [
    particle({
      id: 'google',
      options: particleOptions,
    }),
    particle({
      id: 'apple',
      options: particleOptions,
    }),
    particle({
      options: particleOptions,
    }),
    ...PROD_CONNECTORS,
    coinbaseWallet({ appName: 'wagmi' }),
    injected({ target: 'avalanche' }),
    injected({ target: 'tally' }),
    injected({ target: 'portal' }),
    injected({ target: 'tokenary' }),
    injected({ target: 'xDEFI' }),
    injected({ target: 'status' }),
    injected({ target: 'oneInchAndroidWallet' }),
    injected({ target: 'oneInchIOSWallet' }),
    injected({ target: 'mathWallet' }),
    injected({ target: 'kuCoinWallet' }),
    injected({ target: 'imToken' }),
    injected({ target: 'hyperPay' }),
    injected({ target: 'frontier' }),
    injected({ target: 'frame' }),
    injected({ target: 'dawn' }),
    injected({ target: 'blockWallet' }),
    injected({ target: 'bitski' }),
    injected({ target: 'bifrost' }),
    injected({ target: 'backpack' }),
    injected({ target: 'opera' }),
    injected(),
  ],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  },
})

export const WagmiProvider: FC<PropsWithChildren> = ({ children }) => {
  return <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>
}
