import { FormattedMessage } from '@x10/lib-core/i18n'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

export const NotificationLiquidationMarginCall = ({
  callNumber = 1,
}: {
  callNumber?: 1 | 2
}) => {
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.red',
        }}
      >
        <SvgIcon.SvgIconExclamationTriangle />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id={
                callNumber === 1
                  ? 'core.component.notification.liquidation.margin-call-1.title'
                  : 'core.component.notification.liquidation.margin-call-2.title'
              }
              defaultMessage="Margin Call"
            />
          </Notification.Title>
        </Toast.Title>

        <Notification.Description>
          <FormattedMessage
            id={
              callNumber === 1
                ? 'core.component.notification.liquidation.margin-call-1.message'
                : 'core.component.notification.liquidation.margin-call-2.message'
            }
            defaultMessage="Please add funds or reduce open positions to prevent liquidation."
          />
        </Notification.Description>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
