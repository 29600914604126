import { type FC } from 'react'

import { FormattedMessage, useIntl, type MessageDescriptor } from '@x10/lib-core/i18n'
import { getAssetUrl, notReachable, type Decimal } from '@x10/lib-core/utils'
import { Logo, Notification, Toast } from '@x10/lib-ui-kit/components'

import type { MarketName, OrderType } from '@src/domain/api/x10/common'
import { ApiErrorCode } from '@src/domain/core/errors/base'
import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'

const getReasonMessage = (
  reason: TradeOrderEditCancellationReasons | undefined,
): MessageDescriptor | undefined => {
  switch (reason) {
    case 'maximum-position-value-exceeded':
      return {
        id: 'core.component.notification.order.edit-cancelled.reason.maximum-position-value-exceeded',
        defaultMessage:
          'If executed, the updated order will exceed the maximum position value.',
      }
    case 'insufficient-balance':
      return {
        id: 'core.component.notification.order.edit-cancelled.reason.insufficient-balance',
        defaultMessage: 'Your available balance is insufficient to update the order.',
      }
    case 'contradict-post-only':
      return {
        id: 'core.component.notification.order.edit-cancelled.reason.contradict-post-only',
        defaultMessage:
          'If placed, the updated order would contradict the post-only requirement.',
      }
    case 'execution-price-exceeds-5':
      return {
        id: 'core.component.notification.order.edit-cancelled.reason.execution-price-exceeds-5',
        defaultMessage: 'There is no liquidity at the new price.',
      }
    case undefined:
      return undefined
    default:
      return notReachable(reason)
  }
}

export type TradeOrderEditCancellationReasons =
  | 'maximum-position-value-exceeded'
  | 'insufficient-balance'
  | 'contradict-post-only'
  | 'execution-price-exceeds-5'

const ERRORS_TO_ORDER_EDIT_CANCELLATION_REASONS: Record<
  string,
  TradeOrderEditCancellationReasons
> = {
  [ApiErrorCode.MaxPositionValueExceeded]: 'maximum-position-value-exceeded',
  [ApiErrorCode.OrderCostExceedsBalance]: 'insufficient-balance',

  // Subset of rejection/cancellation reasons from X10 matching engine:
  // https://github.com/x10xchange/matching-engine-v2/blob/main/src/main/kotlin/exchange/x10/matching/model/order/OrderStatusReason.kt
  NO_LIQUIDITY: 'execution-price-exceeds-5',
  POST_ONLY_FAILED: 'contradict-post-only',
}

const getOrderEditCancellationReason = (
  error: string | number | undefined,
): TradeOrderEditCancellationReasons | undefined => {
  const reason = error ? ERRORS_TO_ORDER_EDIT_CANCELLATION_REASONS[error] : undefined

  if (!reason) {
    // if error is DEFINED but not in the mapping, we want to know about it
    error &&
      captureExceptionWithSentry(new Error(`Unknown trade rejection reason: ${error}`))

    return undefined
  }

  return reason
}

export const NotificationOrderEditCancelled: FC<{
  syntheticAmount: Decimal
  direction: 'BUY' | 'SELL'
  marketName: MarketName
  orderType: OrderType
  reason?: string | number
}> = ({ syntheticAmount, direction, marketName, reason, orderType }) => {
  const reasonMessage = getReasonMessage(getOrderEditCancellationReason(reason))
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true, marketName })
  const { formatMessage } = useIntl()
  const getCachedMarket = useGetCachedMarket()

  const { assets } = getCachedMarket(marketName)

  const directionMessage: MessageDescriptor =
    direction === 'BUY'
      ? {
          id: 'core.component.notification.order.direction.buy',
          defaultMessage: 'buy',
        }
      : {
          id: 'core.component.notification.order.direction.sell',
          defaultMessage: 'sell',
        }

  return (
    <Toast.Root>
      <Notification.Avatar>
        <Logo
          url={getAssetUrl({
            type: 'crypto',
            name: assets.synthetic.code,
          })}
          boxSize={40}
        />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id="core.component.notification.order.edit-cancelled.title"
              defaultMessage="{limitOrMarket} {direction} order for {formattedAsset} cancelled."
              values={{
                formattedAsset: formatMarketAsset(
                  {
                    amount: syntheticAmount,
                    type: 'synthetic',
                  },
                  { precision: AssetPrecision.AsIs },
                ),
                direction: formatMessage(directionMessage),
                limitOrMarket:
                  orderType === 'MARKET'
                    ? formatMessage({
                        id: 'core.component.notification.order.expired.market',
                        defaultMessage: 'Market',
                      })
                    : formatMessage({
                        id: 'core.component.notification.order.expired.limit',
                        defaultMessage: 'Limit',
                      }),
              }}
            />
          </Notification.Title>
        </Toast.Title>

        {reasonMessage && (
          <Toast.Description asChild>
            <Notification.Description>
              <FormattedMessage {...reasonMessage} />
            </Notification.Description>
          </Toast.Description>
        )}
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
