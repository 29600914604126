import { FormattedMessage } from '@x10/lib-core/i18n'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

export const NotificationAccountCreated = () => {
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.green',
        }}
      >
        <SvgIcon.SvgIconCheck />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id="core.component.notification.account-created.title"
              defaultMessage="New Account Created"
            />
          </Notification.Title>
        </Toast.Title>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
