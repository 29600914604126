import type { HexString } from '@x10/lib-core/types'

import { useClientSettingsStore } from '@src/domain/core/store/user-settings'

import { appLocalStorage } from './storage/app-local-storage'
import { appSessionStorage } from './storage/app-session-storage'

type WalletToAccountIndex = Record<HexString, Record<number, HexString>>

export const saveAccountPrivateKey = (
  wallet: HexString,
  accountIndex: number,
  privateKey: HexString,
) => {
  const rememberMe = useClientSettingsStore.getState().rememberMe
  const storage = rememberMe ? appLocalStorage : appSessionStorage
  const accounts = storage.getItem<WalletToAccountIndex>('accounts-private-keys') ?? {}
  if (!accounts[wallet]) {
    accounts[wallet] = {}
  }
  accounts[wallet]![accountIndex] = privateKey
  storage.setItem('accounts-private-keys', accounts)
  if (rememberMe) {
    useClientSettingsStore.getState().setRememberMeExpiryDate()
  }
}

export const loadAccountPrivateKey = (wallet: HexString, accountIndex: number) => {
  const rememberMe = useClientSettingsStore.getState().rememberMe
  const storage = rememberMe ? appLocalStorage : appSessionStorage
  const accounts = storage.getItem<WalletToAccountIndex>('accounts-private-keys') ?? {}
  if (rememberMe) {
    // Update the expiry date every time we load private key
    useClientSettingsStore.getState().setRememberMeExpiryDate()
  }
  return accounts[wallet]?.[accountIndex] ?? null
}

export const cleanupAccountsPrivateKeysFromStorages = () => {
  cleanupAccountsPrivateKeysFromLocalStorage()
  appSessionStorage.removeItem('accounts-private-keys')
}

export const cleanupAccountsPrivateKeysFromLocalStorage = () => {
  appLocalStorage.removeItem('accounts-private-keys')
}
