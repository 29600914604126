import { FormattedMessage } from '@x10/lib-core/i18n'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

export const NotificationLiquidationNegativeBalance = () => {
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.red',
        }}
      >
        <SvgIcon.SvgIconCross />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id="core.component.notification.liquidation.negative-balance.title"
              defaultMessage="All Orders Cancelled"
            />
          </Notification.Title>
        </Toast.Title>

        <Notification.Description>
          <FormattedMessage
            id="core.component.notification.liquidation.negative-balance.message"
            defaultMessage="All non-reduce-only orders were canceled due to insufficient available balance."
          />
        </Notification.Description>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
