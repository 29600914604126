import { useCallback, useEffect, useState } from 'react'
import { err, ok, type Result } from 'neverthrow'
import {
  ProviderNotFoundError,
  useAccount,
  useAccountEffect,
  useConfig,
  useConnect,
  type Connector,
} from 'wagmi'
import { create } from 'zustand'
import * as Sentry from '@sentry/nextjs'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { watchAccount } from '@wagmi/core'

import type { HexString } from '@x10/lib-core/types'
import { invariant, toHexString } from '@x10/lib-core/utils'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'
import { useCreateSubAccount as useCreateSubAccountMutation } from '@src/domain/api/hooks/account/use-create-sub-account'
import { useAuthLogin } from '@src/domain/api/hooks/auth/use-auth-login'
import { useAuthLogout } from '@src/domain/api/hooks/auth/use-auth-logout'
import {
  useAuthRegister,
  useFetchWalletEligibility,
} from '@src/domain/api/hooks/auth/use-auth-register'
import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { AccountInfo } from '@src/domain/api/x10/trading/account/account-info.schema'
import { DEFAULT_ACCOUNT_INDEX } from '@src/domain/core/config/static'
import {
  ApiErrorCode,
  AppErrorCode,
  isUserRejectedRequestError,
} from '@src/domain/core/errors/base'
import { parseError } from '@src/domain/core/errors/parse-error'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { useSignTypedData } from '@src/domain/core/hooks/use-sign-typed-data'
import { resetPreviousBalanceState } from '@src/domain/core/store/use-previous-balance-state'
import {
  updateOrInitSettingsStorage,
  useClientSettingsStore,
} from '@src/domain/core/store/user-settings'
import * as analytics from '@src/domain/core/utils/analytics'
import {
  cleanupAccountsPrivateKeysFromLocalStorage,
  cleanupAccountsPrivateKeysFromStorages,
  saveAccountPrivateKey,
} from '@src/domain/core/utils/auth'
import { appLocalStorage } from '@src/domain/core/utils/storage/app-local-storage'
import { calcPedersenHash } from '@src/domain/starkex/utils/calc-pedersen-hash'
import { signMessage } from '@src/domain/starkex/utils/sign-message'
import { starkPrivateKeyFromEthSignature } from '@src/domain/starkex/utils/stark-private-key-from-eth-signature'
import { starkPublicKeyFromStarkPrivateKey } from '@src/domain/starkex/utils/stark-public-key-from-stark-private-key'
import { QueryKey } from '@src/domain/trade/constants'

import { openCreateAccountDialog } from '../components/dialogs/create-account-dialog'
import { getChain } from '../providers/wagmi-provider'
import {
  useWalletEligibilityStore,
  walletEligibilityActions,
} from '../store/wallet-eligibility'
import { useAccountStarkPrivateKey } from './use-account-stark-private-key'

const buildLoginObject = ({ time, useChainId }: { time: Date; useChainId: boolean }) =>
  ({
    types: {
      EIP712Domain: [
        {
          name: 'name',
          type: 'string',
        },
      ],
      Login: [
        {
          name: 'host',
          type: 'string',
        },
        {
          name: 'action',
          type: 'string',
        },
        {
          name: 'time',
          type: 'string',
        },
      ],
    },
    domain: useChainId
      ? {
          name: 'x10.exchange',
          chainId: getChain().id,
        }
      : {
          name: 'x10.exchange',
        },
    primaryType: 'Login',
    message: {
      host: window.location.hostname,
      action: 'LOGIN',
      time: time.toISOString(),
    },
  }) as const

const buildAccountRegisterObject = ({
  wallet,
  accountIndex,
  time,
  useChainId,
}: {
  accountIndex: number
  wallet: HexString
  time: Date
  useChainId: boolean
}) =>
  ({
    types: {
      EIP712Domain: [
        {
          name: 'name',
          type: 'string',
        },
      ],
      AccountRegistration: [
        {
          name: 'accountIndex',
          type: 'int8',
        },
        {
          name: 'wallet',
          type: 'address',
        },
        {
          name: 'tosAccepted',
          type: 'bool',
        },
        {
          name: 'time',
          type: 'string',
        },
        {
          name: 'action',
          type: 'string',
        },
      ],
    },
    domain: useChainId
      ? {
          name: 'x10.exchange',
          chainId: getChain().id,
        }
      : {
          name: 'x10.exchange',
        },
    primaryType: 'AccountRegistration',
    message: {
      accountIndex,
      wallet,
      tosAccepted: true,
      time: time.toISOString(),
      action: 'REGISTER',
    },
  }) as const

const buildSubaccountCreationObject = ({
  wallet,
  accountIndex,
  time,
  useChainId,
}: {
  accountIndex: number
  wallet: HexString
  time: Date
  useChainId: boolean
}) =>
  ({
    types: {
      EIP712Domain: [
        {
          name: 'name',
          type: 'string',
        },
      ],
      AccountRegistration: [
        {
          name: 'accountIndex',
          type: 'int8',
        },
        {
          name: 'wallet',
          type: 'address',
        },
        {
          name: 'tosAccepted',
          type: 'bool',
        },
        {
          name: 'time',
          type: 'string',
        },
        {
          name: 'action',
          type: 'string',
        },
      ],
    },
    domain: useChainId
      ? {
          name: 'x10.exchange',
          chainId: getChain().id,
        }
      : {
          name: 'x10.exchange',
        },
    primaryType: 'AccountRegistration',
    message: {
      accountIndex,
      wallet,
      tosAccepted: true,
      time: time.toISOString(),
      action: 'CREATE_SUB_ACCOUNT',
    },
  }) as const

const buildKeyPairCreation = ({
  wallet,
  accountIndex,
  useChainId,
}: {
  accountIndex: number
  wallet: HexString
  useChainId: boolean
}) =>
  ({
    types: {
      EIP712Domain: [
        {
          name: 'name',
          type: 'string',
        },
      ],
      AccountCreation: [
        {
          name: 'accountIndex',
          type: 'int8',
        },
        {
          name: 'wallet',
          type: 'address',
        },
        {
          name: 'tosAccepted',
          type: 'bool',
        },
      ],
    },
    domain: useChainId
      ? {
          name: 'x10.exchange',
          chainId: getChain().id,
        }
      : {
          name: 'x10.exchange',
        },
    primaryType: 'AccountCreation',
    message: {
      accountIndex: accountIndex,
      wallet: wallet,
      tosAccepted: true,
    },
  }) as const

// Some wallets require `chainId`, while others do not. Additionally, some wallets include `chainId` in the signature, which affects the signing outcomes.
const shouldUseChainId = (connector: Connector | undefined) => {
  return (
    !connector?.name.toLowerCase().includes('backpack') &&
    !connector?.name.toLowerCase().includes('zerion')
  )
}

export const useRegenerateKey = () => {
  const { address, connector } = useAccount()
  const currentAccount = useAccountInfo()
  const { signTypedDataAsync } = useSignTypedData()

  return useCallback(
    async (accountIndex?: number) => {
      try {
        invariant(address, 'Address is required to regenerate key')
        const accIndex =
          accountIndex ?? currentAccount?.accountIndex ?? DEFAULT_ACCOUNT_INDEX

        const l1Signature = await signTypedDataAsync(
          buildKeyPairCreation({
            accountIndex: accIndex,
            wallet: address,
            useChainId: shouldUseChainId(connector),
          }),
        )
        const starkPrivate = starkPrivateKeyFromEthSignature(l1Signature)
        saveAccountPrivateKey(address, accIndex, starkPrivate)
        return ok(starkPrivate)
      } catch (e) {
        if (!isUserRejectedRequestError(e)) {
          throw e
        } else {
          return err('USER_REJECTED_REQUEST' as const)
        }
      }
    },
    [address, connector, currentAccount?.accountIndex, signTypedDataAsync],
  )
}

const isProviderNotFoundError = (e: unknown): e is ProviderNotFoundError => {
  return e instanceof ProviderNotFoundError
}

export const useConnectAccount = () => {
  const { connectAsync } = useConnect()
  const { address: connectedAccountAddress } = useAccount()

  return useCallback(
    async (connector: Connector) => {
      if (!connectedAccountAddress) {
        try {
          const connectResult = await connectAsync({
            connector,
            chainId: getChain().id,
          })

          invariant(connectResult.accounts[0], 'No address found in the connector')
          useAuthState.setState(buildConnectedState(connectResult.accounts[0]))

          return ok(connectResult.accounts[0])
        } catch (e) {
          if (isProviderNotFoundError(e)) {
            return err('PROVIDER_NOT_FOUND')
          } else if (isUserRejectedRequestError(e)) {
            return err('USER_REJECTED_REQUEST')
          }

          throw e
        }
      }

      return ok(connectedAccountAddress)
    },
    [connectedAccountAddress, connectAsync],
  )
}

export const useLogin = () => {
  const { signTypedDataAsync } = useSignTypedData()
  const loginMutation = useAuthLogin()
  const { address, connector } = useAccount()

  return useCallback(async (): Promise<Result<void, 'USER_REJECTED_REQUEST'>> => {
    useAuthState.setState({ isAuthenticating: true })
    try {
      const knownWalletsAddresses = getKnownWalletsSet()
      invariant(address, 'Wallet should be connected to authenticate')
      const onLoggedIn = () => {
        knownWalletsAddresses.add(address)
        appLocalStorage.setItem('known-wallets-addresses', [...knownWalletsAddresses])
      }

      const time = new Date()

      // Has to be removed once this is fixed in Rabby wallet: https://github.com/RabbyHub/Rabby/issues/2146
      if (connector?.name.toLowerCase().includes('rabby')) {
        await wait(500)
      }

      const l1Signature = await signTypedDataAsync(
        buildLoginObject({ time: time, useChainId: shouldUseChainId(connector) }),
      )
      const rememberMe = useClientSettingsStore.getState().rememberMe
      await loginMutation.mutateAsync({ l1Signature, time, rememberMe })
      onLoggedIn()
    } catch (e) {
      if (!isUserRejectedRequestError(e)) {
        throw e
      } else {
        return err('USER_REJECTED_REQUEST')
      }
    } finally {
      useAuthState.setState({ isAuthenticating: false })
    }

    return ok(undefined)
  }, [address, connector, loginMutation, signTypedDataAsync])
}

export const useAuthenticate = () => {
  const register = useRegister()
  const login = useLogin()
  const { address } = useAccount()

  return useCallback(async (): Promise<
    Result<void, 'USER_REJECTED_REQUEST' | 'INVALID_REFERRAL_CODE'>
  > => {
    invariant(address, 'Wallet should be connected to authenticate')
    const isKnown = checkKnownWallet(address)
    if (isKnown) {
      return await login()
    } else {
      return await register()
    }
  }, [address, login, register])
}

// Getting known wallets (e.g. that user already used in this browser) from local storage
const getKnownWalletsSet = () => {
  return new Set(appLocalStorage.getItem<string[]>('known-wallets-addresses') || [])
}

export const checkKnownWallet = (address?: string) => {
  return address && getKnownWalletsSet().has(address)
}

export const useCheckWalletEligibility = () => {
  const isFeatureEnabled = useIsFeatureEnabled()
  const fetchWalletEligibility = useFetchWalletEligibility()
  return useCallback(
    async (address: HexString) => {
      if (isFeatureEnabled('MAINNET_CLOSED')) {
        if (!checkKnownWallet(address)) {
          const walletEligibility = await fetchWalletEligibility({
            l1WalletAddress: address,
          })
          if (walletEligibility.isErr()) {
            return err('WALLET_NOT_ELIGIBLE' as const)
          }
        }
      }
      return ok(undefined)
    },
    [fetchWalletEligibility, isFeatureEnabled],
  )
}

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const useRegister = () => {
  const { signTypedDataAsync } = useSignTypedData()
  const registerMutation = useAuthRegister()
  const { address, connector } = useAccount()
  const { getStarkPrivateKey } = useAccountStarkPrivateKey()

  return useCallback(
    async ({ referralCode }: { referralCode?: string } = {}): Promise<
      Result<void, 'USER_REJECTED_REQUEST' | 'INVALID_REFERRAL_CODE'>
    > => {
      useAuthState.setState({ isAuthenticating: true })
      try {
        invariant(address, 'Wallet should be connected to authenticate')
        const onLoggedIn = () => {
          const knownWalletsAddresses = getKnownWalletsSet()
          knownWalletsAddresses.add(address)
          appLocalStorage.setItem('known-wallets-addresses', [...knownWalletsAddresses])
        }
        const time = new Date()
        // Has to be removed once this is fixed in Rabby wallet: https://github.com/RabbyHub/Rabby/issues/2146
        if (connector?.name.toLowerCase().includes('rabby')) {
          await wait(500)
        }

        const l1Signature = await signTypedDataAsync(
          buildAccountRegisterObject({
            accountIndex: DEFAULT_ACCOUNT_INDEX,
            wallet: address,
            time,
            useChainId: shouldUseChainId(connector),
          }),
        )
        // get starkKey from create-keys payload
        const starkPrivateResult = await getStarkPrivateKey(DEFAULT_ACCOUNT_INDEX)
        if (starkPrivateResult.isErr()) {
          return starkPrivateResult
        }
        const starkPrivate = starkPrivateResult.value
        const starkPublic = starkPublicKeyFromStarkPrivateKey(starkPrivate)
        // derive pedersen hash (l1Address, l2Address)
        const hash = calcPedersenHash([address, starkPublic])
        // sign pedersen hash with private starkKey
        const { signature } = signMessage(hash, starkPrivate)

        const rememberMe = useClientSettingsStore.getState().rememberMe
        await registerMutation.mutateAsync({
          l1Signature: l1Signature,
          l2Key: starkPublic,
          l2Signature: {
            r: toHexString(signature.r),
            s: toHexString(signature.s),
          },
          accountCreation: {
            accountIndex: DEFAULT_ACCOUNT_INDEX,
            wallet: address,
            time,
          },
          referralCode,
          rememberMe,
        })

        onLoggedIn()
      } catch (e) {
        const parsedError = parseError(e)

        if (
          parsedError.type === 'api' &&
          parsedError.code === ApiErrorCode.InvalidReferralCode
        ) {
          return err('INVALID_REFERRAL_CODE')
        }

        if (
          parsedError.type === 'app' &&
          parsedError.code === AppErrorCode.UserRejectedRequest
        ) {
          return err('USER_REJECTED_REQUEST')
        }

        throw e
      } finally {
        useAuthState.setState({ isAuthenticating: false })
      }

      return ok(undefined)
    },
    [address, connector, signTypedDataAsync, getStarkPrivateKey, registerMutation],
  )
}

export const useCreateSubAccount = () => {
  const accounts = useAccounts()
  const { address, connector } = useAccount()
  const createSubAccountMutation = useCreateSubAccountMutation()
  const { signTypedDataAsync } = useSignTypedData()
  const { getStarkPrivateKey } = useAccountStarkPrivateKey()

  const createSubAccount = useCallback(
    async (description: string) => {
      invariant(address, 'Address is required to create sub account')

      const nextIndex =
        accounts
          .map((it) => it.accountIndex)
          .reduce((acc, cur) => Math.max(acc, cur), -1) + 1

      invariant(
        nextIndex >= DEFAULT_ACCOUNT_INDEX,
        `Max index should be greater than ${DEFAULT_ACCOUNT_INDEX}`,
      )

      const time = new Date()
      const useChainId = !connector?.name.toLowerCase().includes('backpack')

      const l1Signature = await signTypedDataAsync(
        buildSubaccountCreationObject({
          accountIndex: nextIndex,
          wallet: address,
          time,
          useChainId,
        }),
      )
      // get starkKey from create-keys payload
      const starkPrivateResult = await getStarkPrivateKey(nextIndex)
      if (starkPrivateResult.isErr()) {
        return starkPrivateResult
      }
      const starkPrivate = starkPrivateResult.value
      const starkPublic = starkPublicKeyFromStarkPrivateKey(starkPrivate)
      const hash = calcPedersenHash([address, starkPublic])
      const { signature } = signMessage(hash, starkPrivate)

      saveAccountPrivateKey(address, nextIndex, starkPrivate)

      return ok(
        await createSubAccountMutation.mutateAsync({
          description,
          l1Signature,
          l2Key: starkPublic,
          l2Signature: {
            r: toHexString(signature.r),
            s: toHexString(signature.s),
          },
          accountCreation: { time, accountIndex: nextIndex, wallet: address },
        }),
      )
    },
    [
      accounts,
      address,
      connector?.name,
      createSubAccountMutation,
      getStarkPrivateKey,
      signTypedDataAsync,
    ],
  )
  return { createSubAccount, isPending: createSubAccountMutation.isPending }
}

const DISCONNECTED_STATE = {
  accountId: undefined,
  accounts: {},
  loginState: 'DISCONNECTED',
  wallet: undefined,
} as const

const buildConnectedState = (wallet: HexString) =>
  ({
    accountId: undefined,
    accounts: {},
    loginState: 'CONNECTED',
    wallet,
  }) as const

// This is needed to dedupe the fact that logout may be called in different places at the same time.
let logoutPromise: Promise<unknown> | null = null

/**
 * Logout is needed to clear the session without disconnecting the wallet
 * @return {() => Promise<unknown>}
 */
export const useLogout = () => {
  const logout = useAuthLogout()
  const { address } = useAccount()
  return useCallback(
    (
      { shouldCleanupPrivateKeys }: { shouldCleanupPrivateKeys?: boolean } = {
        shouldCleanupPrivateKeys: true,
      },
    ) => {
      if (logoutPromise) {
        return logoutPromise
      }
      logoutPromise = logout.mutateAsync().then(() => {
        if (shouldCleanupPrivateKeys) {
          cleanupAccountsPrivateKeysFromStorages()
          useClientSettingsStore.getState().resetRememberMe()
        }
        resetPreviousBalanceState()
        useAuthState.setState(address ? buildConnectedState(address) : DISCONNECTED_STATE)
        logoutPromise = null
      })
      return logoutPromise
    },
    [address, logout],
  )
}

type AuthState = {
  // This can be changed from UI (AccountSelector or so), and it will trigger refetch of the currentAccount below and
  // other account related queries
  accountId: string | undefined
  // Fetched accounts info, shall never be directly modified from the UI code
  accounts: Record<string, AccountInfo>

  wallet: HexString | undefined
  // Representation of the accessToken being valid or not. In case both refresh and access token are expired or missing,
  // it will be set to LOGGED_OUT. In other cases, access token will be refreshed and loginState will be set to LOGGED_IN
  loginState: 'DISCONNECTED' | 'CONNECTED' | 'LOGGED_IN'
  // `isAuthenticating` flag to make sure `data.loginState === "LOGGED_OUT"` doesn't log the user out (effect below)
  // while the user actually in the process of authentication.
  isAuthenticating: boolean
}

// Private store/hook, should not be exported
const useAuthState = create<AuthState>(() => {
  const persistedClientWallet = appLocalStorage.getItem<HexString>('client-wallet')
  const persistedAccountId = appLocalStorage.getItem<string>('account-id')
  const initialState: AuthState = {
    ...DISCONNECTED_STATE,
    isAuthenticating: false,
  }

  if (persistedClientWallet) {
    initialState.wallet = persistedClientWallet
    initialState.loginState = 'CONNECTED'
  }

  if (persistedAccountId) {
    initialState.accountId = persistedAccountId
  }

  updateOrInitSettingsStorage()

  return initialState
})

useAuthState.subscribe((state) => {
  if (state.accountId === undefined) {
    appLocalStorage.removeItem('account-id')

    Sentry.setUser(null)
    analytics.setUserId(undefined)
  } else {
    appLocalStorage.setItem('account-id', state.accountId)

    Sentry.setUser({
      id: state.accountId,
    })
    analytics.setUserId(state.accountId)
  }

  if (state.wallet === undefined) {
    appLocalStorage.removeItem('client-wallet')
  } else {
    appLocalStorage.setItem('client-wallet', state.wallet)
  }

  updateOrInitSettingsStorage()
})

useClientSettingsStore.subscribe((state) => {
  if (!state.rememberMe) {
    cleanupAccountsPrivateKeysFromLocalStorage()
  }
})

// Public API to fork based on loggedIn state
export const useSuspendedIsLoggedIn = () => {
  useHandleAuth() // Inits Auth and suspends until it's ready
  return useAuthState((state) => state.loginState === 'LOGGED_IN')
}

// Public API to refetch all account related data and to change the current account
export const useAccountId = () => {
  useHandleAuth() // Inits Auth and suspends until it's ready
  return useAuthState((state) => state.accountId)
}

useAccountId.setAccountId = (account: AccountInfo) => {
  const accounts = { ...useAuthState.getState().accounts }
  accounts[account.accountId.toString()] = account
  useAuthState.setState({ accountId: account.accountId.toString(), accounts })
  resetPreviousBalanceState()
}

// Public API to get the current account info
export const useAccountInfo = () => {
  useHandleAuth() // Inits Auth and suspends until it's ready
  return useAuthState((state) => state.accounts[state?.accountId || ''])
}

// Public API to get the current account info. Will fail if used unguarded using `useLoggedIn` or `useAccountId` or any other way
export const useGuardedAccountInfo = () => {
  useHandleAuth() // Inits Auth and suspends until it's ready
  return useAuthState((state) => {
    invariant(
      state.accountId,
      'useGuardedAccountInfo should only be used in auth guarded contexts that makes sure user is logged in',
    )
    return state.accounts[state.accountId]!
  })
}

// Public API to get the current account id. Will fail if used unguarded using `useLoggedIn` or `useAccountInfo` or any other way
export const useGuardedAccountId = () => {
  useHandleAuth() // Inits Auth and suspends until it's ready
  return useAuthState((state) => {
    invariant(
      state.accountId,
      'useGuardedAccountId should only be used in auth guarded contexts that makes sure user is logged in',
    )
    return state.accountId
  })
}

const appLoadedTime = Date.now()

const REFRESH_TIME = 30_000
// This is needed to keep access token fresh and never let it expire to avoid 401 when app is running.
// On first load API.auth.getInfo will handle refresh in case accessToken is expired and refreshToken is not.
// But then accessToken should never expire while tab is open.
const useKeepAccessTokenRefreshed = () => {
  const [now, setNow] = useState(Date.now())
  const isLoggedIn = useAuthState((it) => it.loginState === 'LOGGED_IN')
  // trigger set after 30 seconds to update now and enable the query
  useEffect(() => {
    const timeout = setTimeout(() => {
      setNow(Date.now())
    }, REFRESH_TIME + 1000) // 1000 to make sure we past appLoadedTime + REFRESH_TIME :)
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  const TEN_MINUTES = 1000 * 60 * 10
  // keep disabled until now is more than 30 seconds after app loaded
  useQuery({
    refetchInterval: TEN_MINUTES,
    refetchIntervalInBackground: true,
    queryFn: API.auth.refresh,
    queryKey: [],
    enabled: now > appLoadedTime + REFRESH_TIME && isLoggedIn,
  })
}

const fetchAccountInfo = async (accountId: string | undefined) => {
  const accountResponse = await API.trading.account.getAccountInfo(accountId)
  const account = removeRestEnvelopeSuspense(accountResponse)
  const currentAccountId = account.accountId.toString()
  // We collect all the fetched accounts in the map, to avoid external sync of react-query cache and zustand state on accountId external change.
  const accounts = { ...useAuthState.getState().accounts }

  accounts[currentAccountId] = account

  useAuthState.setState({
    accountId: currentAccountId,
    accounts,
    loginState: 'LOGGED_IN',
  })

  return useAuthState.getState()
}

const useHandleWalletChange = () => {
  const logout = useLogout()
  const checkWalletEligibility = useCheckWalletEligibility()
  const isCloseMainnetBannerVisible = useWalletEligibilityStore(
    (it) => it.isClosedMainnetBannerVisible,
  )
  const wagmiConfig = useConfig()
  useEffect(() => {
    const unwatch = watchAccount(wagmiConfig, {
      onChange(account, prevAccount) {
        if (
          prevAccount.address !== undefined &&
          account.address !== undefined &&
          account.address !== prevAccount.address
        ) {
          checkWalletEligibility(account.address).then((eligibilityResult) => {
            if (eligibilityResult.isErr()) {
              walletEligibilityActions.setReferralCodeRequired()
              return
            }
            logout({ shouldCleanupPrivateKeys: false }).then(() => {
              if (!isCloseMainnetBannerVisible) {
                openCreateAccountDialog()
              }
            })
            walletEligibilityActions.triggerPostAuthUnlocking()
          })
        }
      },
    })

    return () => {
      unwatch()
    }
  }, [wagmiConfig, logout, checkWalletEligibility, isCloseMainnetBannerVisible])
}

const useHandleAuth = () => {
  // we do not use here useAccountId as it causes infinite loop
  const accountId = useAuthState((it) => it.accountId)
  useKeepAccessTokenRefreshed()
  const { address } = useAccount()
  const logout = useLogout()

  useSuspenseQuery({
    queryKey: [QueryKey.UserAccountInfo, accountId],
    // Data from this query will never be used directly. useAuth derived hooks shall be used instead as a public API
    // to this state. Note that we should always return something (not undefined) from the queryFn, otherwise it will
    // be considered as an error and will be retried and effectively error out.
    queryFn: async () => {
      const { accessExpired, refreshExpired } = await API.auth.getInfo()
      // any of tokens are fresh, yet wallet is disconnected
      if ((!accessExpired || !refreshExpired) && !address) {
        await logout()
        return useAuthState.getState()
      }
      if (accessExpired && !refreshExpired) {
        try {
          await API.auth.refresh()
          return fetchAccountInfo(accountId)
        } catch (e) {
          throw Error('Failed to refresh token')
        }
      }
      if (accessExpired && refreshExpired) {
        useAuthState.setState(address ? buildConnectedState(address) : DISCONNECTED_STATE)
        return useAuthState.getState()
      }
      return fetchAccountInfo(accountId)
    },
  })

  useHandleWalletChange()

  useAccountEffect({
    // we don't have special `useDisconnect` so we handle disconnect logic here
    onDisconnect() {
      logout().finally(() => {
        useAuthState.setState(DISCONNECTED_STATE)
      })
    },
  })
}
