import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { getAssetUrl } from '@x10/lib-core/utils'
import { styled } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Link,
  Notification,
  SvgIcon,
  Toast,
} from '@x10/lib-ui-kit/components'
import { getComputedFontSize } from '@x10/lib-ui-kit/utils'

import { openExternalLink } from '@src/domain/core/utils/open-external-link'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'

export const NotificationCompetition = ({ onClose }: { onClose: VoidFunction }) => {
  const [marginBottom, setMarginBottom] = useState(
    `${(window.innerHeight - 250) / getComputedFontSize()}rem`,
  )

  useEffect(() => {
    const updateMarginBottom = throttle(() => {
      setMarginBottom(`${(window.innerHeight - 250) / getComputedFontSize()}rem`)
    }, 1000)

    window.addEventListener('resize', updateMarginBottom)

    return () => window.removeEventListener('resize', updateMarginBottom)
  }, [])

  return (
    <Toast.Root
      asChild
      style={{
        marginBottom,
      }}
      css={{
        transition: 'scale 0.2s ease-in-out',
        cursor: 'pointer',
        _hover: {
          scale: 1.03,
        },
      }}
      onClick={() => {
        openExternalLink(
          asExternalRoute(documentationRoute({}).testnet({}).tradingCompetitions({}).$),
        )
      }}
    >
      <Notification.Avatar>
        <styled.img
          css={{
            maxH: '2.5rem',
            maxW: '2.5rem',
          }}
          src={getAssetUrl({
            type: '3d-icon',
            name: 'claim-your-rebates',
          })}
        />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id="notification.competition.title"
              defaultMessage="The Trading Competition is live!"
            />
          </Notification.Title>
        </Toast.Title>
        <Toast.Description asChild>
          <Notification.Description
            css={{
              mt: 's-8',
            }}
          >
            <FormattedMessage
              id="notification.competition.description"
              defaultMessage="Climb the Leaderboard and win <competition-link>USDC prizes!</competition-link>"
              values={{
                'competition-link': (chunks) => (
                  <Link visual="primary-green" href={'#'}>
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Notification.Description>
        </Toast.Description>
      </Notification.Content>
      <Toast.CloseTrigger asChild>
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        >
          <SvgIcon.SvgIconCross />
        </ActionIcon>
      </Toast.CloseTrigger>
    </Toast.Root>
  )
}
