const AuthQueryKeyDomain = 'auth'

export const AuthQueryKey = {
  Info: `${AuthQueryKeyDomain}.info`,
  WalletEligibility: `${AuthQueryKeyDomain}.wallet_eligibility`,
} as const

export enum DefaultMarketCategory {
  All = 'all',
  Favorites = 'favorites',
}
