import { FormattedMessage } from '@x10/lib-core/i18n'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

export const NotificationLiquidation = () => {
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.red',
        }}
      >
        <SvgIcon.SvgIconExclamationTriangle />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              id="core.component.notification.liquidation.liquidation.title"
              defaultMessage="Liquidation"
            />
          </Notification.Title>
        </Toast.Title>

        <Toast.Description asChild>
          <Notification.Description>
            <FormattedMessage
              id="core.component.notification.liquidation.liquidation.message"
              defaultMessage="You will regain control once account margin ratio falls <100%."
            />
          </Notification.Description>
        </Toast.Description>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
