import type { FC, PropsWithChildren } from 'react'

import type { SystemStyleObject } from '@x10/lib-styled-system/types'
import { Dialog } from '@x10/lib-ui-kit/components'

import { CSS_STATIC_COLUMN_WIDTH_VAR } from '@src/domain/core/config/static'

type DialogContentProps = {
  css?: SystemStyleObject
  variant?: 'default' | 'wide'
}

export const DialogContent: FC<PropsWithChildren<DialogContentProps>> = ({
  css,
  variant,
  children,
}) => {
  return (
    <Dialog.Content
      style={{
        width: variant === 'wide' ? '25rem' : `var(${CSS_STATIC_COLUMN_WIDTH_VAR})`,
      }}
      css={[{ minW: 'unset!' }, css ?? {}]}
    >
      {children}
    </Dialog.Content>
  )
}
