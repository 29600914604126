import { FormattedMessage, useIntl, type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'

export type TpslTradeEvents =
  | 'placed'
  | 'cancelled'
  | 'rejected'
  | 'expired'
  | 'triggered'

const getTradeMessages = (event: TpslTradeEvents): MessageDescriptor => {
  switch (event) {
    case 'placed':
      return {
        id: 'core.component.notification.order.placed.tpsl.title',
        defaultMessage: '{tpslPrefix} on {syntheticCurrencyCode} {article} placed.',
      }
    case 'cancelled':
      return {
        id: 'core.component.notification.order.cancelled.tpsl.title',
        defaultMessage: '{tpslPrefix} on {syntheticCurrencyCode} {article} cancelled.',
      }
    case 'rejected':
      return {
        id: 'core.component.notification.order.rejected.tpsl.title',
        defaultMessage: '{tpslPrefix} on {syntheticCurrencyCode} {article} rejected.',
      }
    case 'expired':
      return {
        id: 'core.component.notification.order.expired.tpsl.title',
        defaultMessage: '{tpslPrefix} on {syntheticCurrencyCode} {article} expired.',
      }
    default:
      return notReachable(event)
  }
}

type TpSlCombo = 'tpsl' | 'sl' | 'tp'

const getTpslPrefix = (tpOrSlOrBoth: TpSlCombo): MessageDescriptor => {
  switch (tpOrSlOrBoth) {
    case 'tpsl':
      return {
        id: 'core.component.notification.trade.tpsl.prefix',
        defaultMessage: 'Take Profit and Stop Loss orders',
      }
    case 'sl':
      return {
        id: 'core.component.notification.trade.sl.prefix',
        defaultMessage: 'Stop Loss order',
      }
    case 'tp':
      return {
        id: 'core.component.notification.trade.tp.prefix',
        defaultMessage: 'Take Profit order',
      }
    default:
      return notReachable(tpOrSlOrBoth)
  }
}

const getArticleByTpsl = (tpOrSlOrBoth: TpSlCombo): MessageDescriptor => {
  switch (tpOrSlOrBoth) {
    case 'tpsl':
      return {
        id: 'core.component.notification.trade.tpsl.article',
        defaultMessage: 'are',
      }
    case 'sl':
    case 'tp':
      return {
        id: 'core.component.notification.trade.sl.article',
        defaultMessage: 'is',
      }
    default:
      return notReachable(tpOrSlOrBoth)
  }
}

// TODO: To be removed if we decide it's 100% not needed anymore
// https://linear.app/ex10/issue/X10-2934/remove-tpsl-notifications-code
export const NotificationTradeTpsl = ({
  marketName,
  event,
  tpsl,
}: {
  marketName: MarketName
  tpsl: TpSlCombo
  event: TpslTradeEvents
}) => {
  const message = getTradeMessages(event)
  const { formatMessage } = useIntl()
  const getCachedMarket = useGetCachedMarket()

  const { assets } = getCachedMarket(marketName)

  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          color: 'token.red',
        }}
      >
        <SvgIcon.SvgIconExclamationTriangle />
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            <FormattedMessage
              {...message}
              values={{
                tpslPrefix: formatMessage(getTpslPrefix(tpsl)),
                syntheticCurrencyCode: assets.synthetic.code,
                article: formatMessage(getArticleByTpsl(tpsl)),
              }}
            />
          </Notification.Title>
        </Toast.Title>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
