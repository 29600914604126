import { FormattedMessage } from '@x10/lib-core/i18n'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

export const NotificationTransfer = ({ status }: { status: 'success' | 'failed' }) => {
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: status === 'success' ? 'token.green' : 'token.red',
        }}
      >
        {status === 'success' ? <SvgIcon.SvgIconCheck /> : <SvgIcon.SvgIconCross />}
      </Notification.Avatar>

      <Notification.Content>
        <Toast.Title asChild>
          <Notification.Title>
            {status === 'success' ? (
              <FormattedMessage
                id="core.component.notification.transfer.completed.title"
                defaultMessage="Transfer Completed"
              />
            ) : (
              <FormattedMessage
                id="core.component.notification.transfer.failed.title"
                defaultMessage="Transfer Failed"
              />
            )}
          </Notification.Title>
        </Toast.Title>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
