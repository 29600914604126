import { useCallback } from 'react'
import { fromPromise } from 'neverthrow'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { HexString } from '@x10/lib-core/types'

import { API } from '@src/domain/api/x10'
import { AuthQueryKey } from '@src/domain/core/constants'
import { AppErrorCode, X10AppError } from '@src/domain/core/errors/base'
import { queryClient } from '@src/domain/core/providers/app-providers/query-client'
import { QueryKey } from '@src/domain/trade/constants'

export const useAuthRegister = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: API.auth.register,

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.UserAccountInfo] })
    },
  })
}

export const useFetchWalletEligibility = () => {
  return useCallback(async ({ l1WalletAddress }: { l1WalletAddress: HexString }) => {
    const result = queryClient.fetchQuery({
      queryKey: [AuthQueryKey.WalletEligibility, l1WalletAddress],
      queryFn: () => {
        return API.auth.getWalletEligibility({
          l1WalletAddress,
        })
      },
      retry: false,
    })

    return fromPromise(result, () => new X10AppError(AppErrorCode.WalletIsNotEligible))
  }, [])
}
