import { useCallback } from 'react'
import { useSignTypedData as useSignTypedDataWagmi, useSwitchChain } from 'wagmi'
import type { UseSignTypedDataParameters, UseSignTypedDataReturnType } from 'wagmi'
import { getAccount } from '@wagmi/core'

import { getChain, wagmiConfig } from '@src/domain/auth/providers/wagmi-provider'

export function useSignTypedData<context = unknown>(
  parameters: UseSignTypedDataParameters<context> = {},
): UseSignTypedDataReturnType<context> {
  const signTypedDataReturn = useSignTypedDataWagmi(parameters)
  const { switchChain, switchChainAsync } = useSwitchChain()

  const { signTypedDataAsync, signTypedData } = signTypedDataReturn

  signTypedDataReturn.signTypedDataAsync = useCallback(
    async (variables, options) => {
      if (getAccount(wagmiConfig).chainId !== getChain().id) {
        await switchChainAsync({
          chainId: getChain().id,
        })
      }
      return signTypedDataAsync(variables, options)
    },
    [signTypedDataAsync, switchChainAsync],
  )

  signTypedDataReturn.signTypedData = useCallback(
    (variables, options) => {
      if (getAccount(wagmiConfig).chainId !== getChain().id) {
        switchChain({
          chainId: getChain().id,
        })
      }
      return signTypedData(variables, options)
    },
    [signTypedData, switchChain],
  )

  return signTypedDataReturn
}
