import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { Notification, SvgIcon, Toast } from '@x10/lib-ui-kit/components'

import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'

type NotificationDepositProps = {
  amount: number | Decimal
}

export const NotificationDepositCompleted: FC<NotificationDepositProps> = ({
  amount,
}) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: false })
  return (
    <Toast.Root>
      <Notification.Avatar
        css={{
          bg: 'token.green',
        }}
      >
        <SvgIcon.SvgIconDeposit />
      </Notification.Avatar>

      <Notification.Content>
        <Notification.TitleAndSide>
          <Toast.Title asChild>
            <Notification.Title>
              <FormattedMessage
                id="core.component.notification.deposit.completed.title"
                defaultMessage="Deposit Completed"
              />
            </Notification.Title>
          </Toast.Title>
          <Notification.Side>
            <Notification.Value>
              +
              {formatMarketAsset(
                {
                  amount: amount,
                  type: 'collateral',
                },
                { precision: AssetPrecision.AsIs },
              )}{' '}
              USDC
            </Notification.Value>
          </Notification.Side>
        </Notification.TitleAndSide>
      </Notification.Content>
      <Toast.CloseTrigger />
    </Toast.Root>
  )
}
