import { Suspense, type FC, type ReactNode } from 'react'

import { useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'

type LoggedInWrapperProps = {
  children: ReactNode
}

const LoggedInWrapper: FC<LoggedInWrapperProps> = ({ children }) => {
  const isLoggedIn = useSuspendedIsLoggedIn()

  if (!isLoggedIn) {
    return null
  }

  return children
}

type LoggedInGuardProps = {
  children: React.ReactNode
  fallback?: React.ReactNode
}

export const LoggedInGuard: FC<LoggedInGuardProps> = ({ children, fallback = null }) => (
  <Suspense fallback={fallback}>
    <LoggedInWrapper>{children}</LoggedInWrapper>
  </Suspense>
)
